import React, { useEffect, useState, Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Modal from "./Modal";
import WatchHistoryRequest from "./WatchHistoryRequest";
import ir from "../images/tesoreria/ir.png";
import aprobado from "../images/tesoreria/aprobado_responsable.png";
import rechazado from "../images/tesoreria/rechazado_responsable.png";
import notFound from "../images/tesoreria/notfound.png";
import esperar from "../images/tesoreria/letra.png";
import historial from "../images/tesoreria/history.png";
import flecha from "../images/tesoreria/flecha.png";
import en_revision from "../images/tesoreria/lupa.png";
import esperar_tesoreria from "../images/tesoreria/reloj-de-arena.png";
import revision_documentos from "../images/tesoreria/search.png";
import aprobado_tesoreria from "../images/tesoreria/aprobado_tesoreria2.png";
import rechazado_tesoreria from "../images/tesoreria/rechazado_tesoreria2.png";
import cancelado from "../images/tesoreria/cancelar.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { useAuth } from "../context/auth/store";

const ListRequest = (props) => {
  const auth = useAuth();
  const history = useNavigate();
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const quince_dias_atras = new Date();
  quince_dias_atras.setDate(hoy.getDate() - 15);
  const array_fecha_fin = [
    hoy.getDate().toString(),
    (hoy.getMonth() + 1).toString(),
    hoy.getFullYear().toString(),
  ];
  if (array_fecha_fin[1].length === 1) {
    array_fecha_fin[1] = "0" + array_fecha_fin[1];
  }
  if (array_fecha_fin[0].length === 1) {
    array_fecha_fin[0] = "0" + array_fecha_fin[0];
  }

  const array_fecha_inicio = [
    quince_dias_atras.getDate().toString(),
    (quince_dias_atras.getMonth() + 1).toString(),
    quince_dias_atras.getFullYear().toString(),
  ];
  if (array_fecha_inicio[1].length === 1) {
    array_fecha_inicio[1] = "0" + array_fecha_inicio[1];
  }
  if (array_fecha_inicio[0].length === 1) {
    array_fecha_inicio[0] = "0" + array_fecha_inicio[0];
  }
  const location = useLocation();
  const ruta_enlace =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  const ruta_principal =
    location.pathname.split("/")[location.pathname.split("/").length - 2];
  const [estadoModal, setEstadoModal] = useState(false);

  const dateToday =
    array_fecha_fin[2] + "-" + array_fecha_fin[1] + "-" + array_fecha_fin[0];
  const dateQuinceDiasAtras =
    array_fecha_inicio[2] +
    "-" +
    array_fecha_inicio[1] +
    "-" +
    array_fecha_inicio[0];
  const [request, setRequest] = useState([]);
  const [cambiarModo, setCambiarModo] = useState(true);
  const [identificacionEstudiante, setIdentificacionEstudiante] = useState("");
  const [solicitudesPorInterventor, setSolicitudesPorInterventor] = useState(
    []
  );
  const [idSolicitud, setIdSolicitud] = useState(0);
  const [fechaDescarga, setFechaDescarga] = useState({
    fecha_inicio: dateQuinceDiasAtras,
    fecha_fin: dateToday,
  });
  const [fechaFiltrarLista, setFechaFiltrarLista] = useState({
    fecha_inicio: dateQuinceDiasAtras,
    fecha_fin: dateToday,
  });

  const selectOptions = {
    0: "En Proceso - Responsable del Seguimiento",
    1: "Rechazado - Responsable del Seguimiento",
    2: "Aceptado - Responsable del Seguimiento || Pendiente Para Revisar Documentos - Tesoreria",
    3: "Aceptado - Responsable del Seguimiento || Pendiente Para Cargar ORPA - Tesoreria",
    4: "Aceptado - Responsable del Seguimiento || Pendiente Para Revisar ORPA - Tesoreria",
    5: "Aceptado - Responsable del Seguimiento || Aceptado - Tesoreria",
    6: "Aceptado - Responsable del Seguimiento || Rechazado - Tesoreria",
    8: "Cancelado",
  };

  const columns = [
    {
      dataField: "cumplido_id",
      text: "Id Consecutivo",
      filter: textFilter(),
    },
    {
      dataField: "fecha_solicitud",
      formatter: celdaFechaSolicitud,
      text: "Fecha de Solicitud",
      filter: textFilter(),
    },
    {
      dataField: "cc_solicitante",
      text: "Identificación Estudiante",
      filter: textFilter(),
      headerStyle: () => {
        return { width: "10%" };
      },
    },
    {
      dataField:
        ruta_enlace === "cumplidos-con-orpa" ||
        ruta_enlace === "lista-solicitudes" ||
        ruta_enlace === "consultar-solicitudes" ||
        ruta_enlace === "reportes" ||
        ruta_enlace === "lista-solicitudes-revisadas"
          ? "empresa"
          : "tipo_vinculacion",
      text:
        ruta_enlace === "cumplidos-con-orpa" ||
        ruta_enlace === "lista-solicitudes" ||
        ruta_enlace === "consultar-solicitudes" ||
        ruta_enlace === "reportes" ||
        ruta_enlace === "lista-solicitudes-revisadas"
          ? "Empresa QUIPU"
          : "Tipo de Pago",

      filter: textFilter(),
    },
    {
      dataField:
        ruta_enlace === "gestionar-solicitudes"
          ? "nombre_solicitante"
          : "nombre_interventor",
      text:
        ruta_enlace === "gestionar-solicitudes"
          ? "Nombre Estudiante"
          : "Responsable del seguimiento",
      filter: textFilter(),
    },
    {
      dataField: "estado_cumplido",
      text: "Estado Solicitud",
      formatter: celdaEstadoSolicitud,
      filter: selectFilter({
        options: selectOptions,
      }),
    },
    {
      dataField: "solicitud_id",
      formatter: historyFormatter,
      text: "Historial",
      headerStyle: () => {
        return { width: "8%" };
      },
    },
    {
      dataField: "solicitud_id",
      formatter: optionFormatter,
      headerStyle: () => {
        return { width: "8%" };
      },

      text: "Aceptar / Rechazar",
    },
  ];

  function optionFormatter(e) {
    if (ruta_enlace !== "cumplidos-con-orpa") {
      return (
        <Link
          to={{
            pathname: "/solicitud",
          }}
          state={{
            id: e,
            tipo:
              ruta_enlace === "gestionar-solicitudes"
                ? "solicitud_para_interventor"
                : ruta_enlace === "mis-solicitudes"
                ? "solicitud_usuario"
                : ruta_enlace === "lista-solicitudes"
                ? "solicitud_para_revisar"
                : ruta_enlace === "lista-solicitudes-revisadas"
                ? "solicitudes_revisadas"
                : ruta_enlace === "consultar-solicitudes"
                ? "solicitudes_totales"
                : "",
          }}
        >
          <img alt="ir" src={ir} />
        </Link>
      );
    } else {
      if (
        solicitudesPorInterventor.filter(
          (element) => element.solicitud_id === e
        ).length === 0
      ) {
        return (
          <Link
            to={{
              pathname: "/solicitud",
            }}
            state={{
              id: e,
              tipo: "solicitud_con_orpa",
            }}
          >
            <img alt="ir" src={ir} />
          </Link>
        );
      } else {
        return (
          <div onClick={toastNoAutorizado}>
            <img
              alt="Usted no se encuentra autorizado para aprobar esta solicitud."
              src={ir}
            />
          </div>
        );
      }
    }
  }
  function historyFormatter(e) {
    return (
      <div>
        <Link
          to={{
            pathname: "/" + ruta_enlace,
          }}
          state={{
            id: e,
          }}
        >
          <img
            data-toggle="modal"
            data-target=".bd-example-modal-lg"
            onClick={() => {
              setIdSolicitud(e);
              if (estadoModal === false) {
                setEstadoModal(!estadoModal);
              }
            }}
            alt="historial"
            src={historial}
          />
        </Link>
      </div>
    );
  }

  const toastNoAutorizado = () => {
    toast.error(
      "Usted aprobó esta solicitud, no se encuentra autorizado para revisarla.",
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const pressButtonSearchEstudiante = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      verSolicitudesTotales("doc");
    }
  };


  const descargarExcelSolicitudesRevisadas = async () => {
    var nombre_descarga = "";
    var direccion = "";
    if (ruta_enlace === "lista-solicitudes") {
      nombre_descarga = "solicitudes_sin_aprobar_y_sin_revisar.xlsx";
      direccion = "exportar-excel-solicitudes-sin-revisar";
    } else if (ruta_enlace === "lista-solicitudes-revisadas") {
      nombre_descarga = "solicitudes_con_documentacion_revisada.xlsx";
      direccion = "exportar-excel-solicitudes-revisadas";
    } else if (ruta_enlace === "cumplidos-con-orpa") {
      nombre_descarga = "solicitudes_con_orpa.xlsx";
      direccion = "exportar-excel-solicitudes-con-orpa";
    } else if (ruta_enlace === "reportes") {
      nombre_descarga = "reporte_consolidado.xlsx";
      direccion = "exportar-reporte-consolidado";
    }
    const message_toast = toast.loading("Generando Documento...");
    await axios
      .post(
        `${process.env.REACT_APP_URL}/${direccion}/`,
        { fechaDescarga },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        if (response.status == 201) {
          toast.update(message_toast, {
            render: <div>Documento Generado</div>,
            type: "success",
            closeOnClick: true,
            autoClose: 5000,
            isLoading: false,
          });
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/vnd.ms-excel" })
          );
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", nombre_descarga);
          document.body.appendChild(link);
          link.click();
        } else {
          toast.update(message_toast, {
            render: <div>No hay datos para descargar</div>,
            type: "success",
            closeOnClick: true,
            autoClose: 5000,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        toast.update(message_toast, {
          render: <div>Ocurrió un error.</div>,
          type: "error",
          closeOnClick: true,
          autoClose: 5000,
          isLoading: false,
        });
      });
  };

  function celdaEstadoSolicitud(cell) {
    switch (cell) {
      case 0:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  En Espera - Responsable de Seguimiento
                </Popover.Header>
                <Popover.Body>
                  Esta solicitud está pendiente de ser aprobada por el
                  responsable del gasto.
                </Popover.Body>
              </Popover>
            }
          >
            <img alt="esperar" src={esperar} />
          </OverlayTrigger>
        );

      case 1:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Rechazado - Responsable de Seguimiento
                </Popover.Header>
                <Popover.Body>
                  Esta solicitud ha sido rechazada por el responsable del gasto.
                </Popover.Body>
              </Popover>
            }
          >
            <img alt="rechazado" src={rechazado} />
          </OverlayTrigger>
        );
      case 2:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Aprobado - Responsable del Seguimiento || Pendiente Para
                  Revisar Documentos - Tesoreria
                </Popover.Header>
                <Popover.Body>
                  Esta constancia de cumplimiento ha sido aprobada por el
                  responsable del gasto y se encuentra pendiente para revisar
                  los documentos por parte de tesorería.
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <img alt="aprobado" src={aprobado} />
              <img alt="flecha" src={flecha} />
              <img alt="esperar" src={esperar_tesoreria} />
            </div>
          </OverlayTrigger>
        );
      case 3:
        if (ruta_enlace === "mis-solicitudes") {
          return (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Header as="h3">
                    Aprobado - Responsable del Seguimiento || Pendiente Para
                    Revisar Documentos - Tesoreria
                  </Popover.Header>
                  <Popover.Body>
                    Esta constancia de cumplimiento ha sido aprobada por el
                    responsable del gasto y se encuentra pendiente para revisar
                    los documentos por parte de tesorería.
                  </Popover.Body>
                </Popover>
              }
            >
              <div>
                <img alt="aprobado" src={aprobado} />
                <img alt="flecha" src={flecha} />
                <img alt="esperar" src={esperar_tesoreria} />
              </div>
            </OverlayTrigger>
          );
        } else {
          return (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Header as="h3">
                    Aprobado - Responsable del Seguimiento || Pendiente Para
                    Cargar ORPA - Tesoreria
                  </Popover.Header>
                  <Popover.Body>
                    Esta constancia de cumplimiento ha sido aprobada por el
                    responsable del gasto y se encuentra pendiente para cargar
                    la orden de pago por parte de tesorería.
                  </Popover.Body>
                </Popover>
              }
            >
              <div>
                <img alt="aprobado" src={aprobado} />
                <img alt="flecha" src={flecha} />
                <img alt="esperar" src={revision_documentos} />
              </div>
            </OverlayTrigger>
          );
        }
      case 4:
        if (ruta_enlace === "mis-solicitudes") {
          return (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Header as="h3">
                    Aprobado - Responsable del Seguimiento || Pendiente Para
                    Revisar Documentos - Tesoreria
                  </Popover.Header>
                  <Popover.Body>
                    Esta constancia de cumplimiento ha sido aprobada por el
                    responsable del gasto y se encuentra pendiente para revisar
                    los documentos por parte de tesorería.
                  </Popover.Body>
                </Popover>
              }
            >
              <div>
                <img alt="aprobado" src={aprobado} />
                <img alt="flecha" src={flecha} />
                <img alt="esperar" src={esperar_tesoreria} />
              </div>
            </OverlayTrigger>
          );
        } else {
          return (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Header as="h3">
                    Aprobado - Responsable del Seguimiento || Pendiente Para
                    Revisar ORPA - Tesoreria
                  </Popover.Header>
                  <Popover.Body>
                    Esta constancia de cumplimiento ha sido aprobada por el
                    responsable del gasto y se encuentra en pendiente para
                    revisar la orden de pago por parte de tesorería.
                  </Popover.Body>
                </Popover>
              }
            >
              <div>
                <img alt="aprobado" src={aprobado} />
                <img alt="flecha" src={flecha} />
                <img alt="en revision" src={en_revision} />
              </div>
            </OverlayTrigger>
          );
        }
      case 5:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Aprobado - Responsable del Seguimiento || Aceptado - Tesoreria
                </Popover.Header>
                <Popover.Body>
                  Esta constancia de cumplimiento ha sido aprobada por el
                  responsable del gasto y por tesorería.
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <img alt="aprobado" src={aprobado} />
              <img alt="flecha" src={flecha} />
              <img alt="aprobado" src={aprobado_tesoreria} />
            </div>
          </OverlayTrigger>
        );
      case 6:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Aprobado - Responsable del Seguimiento || Rechazado -
                  Tesoreria
                </Popover.Header>
                <Popover.Body>
                  Esta constancia de cumplimiento ha sido aprobada por el
                  responsable del gasto y rechazada por parte de tesorería.
                </Popover.Body>
              </Popover>
            }
          >
            <div>
              <img alt="aprobado" src={aprobado} />
              <img alt="flecha" src={flecha} />
              <img alt="rechazado" src={rechazado_tesoreria} />
            </div>
          </OverlayTrigger>
        );
      case 8:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Cancelada por el usuario
                </Popover.Header>
                <Popover.Body>
                  Esta solicitud ha sido cancelada por el usuario.
                </Popover.Body>
              </Popover>
            }
          >
            <img alt="cancelado" src={cancelado} />
          </OverlayTrigger>
        );
      default:
        break;
    }
  }

  function celdaFechaSolicitud(cell) {
    return cell.split("T")[0] + "-" + cell.split("T")[1].split(".")[0];
  }

  useEffect(() => {
    if (ruta_enlace === "mis-solicitudes") {
      verSolicitudesxUser(auth.user.email);
    } else if (ruta_enlace === "gestionar-solicitudes") {
      verSolicitudesxInterventor(auth.user.email);
    } else {
      if (auth.user.rol !== 0) {
        if (ruta_enlace === "lista-solicitudes") {
          verSolicitudes(cambiarModo);
        } else if (ruta_enlace === "lista-solicitudes-revisadas") {
          limpiarTablaLog();
          verSolicitudesRevisadas();
        } else if (ruta_enlace === "cumplidos-con-orpa") {
          verSolicitudesConOrpa();
          buscarSolicitudesRevisadasPorUsuario(auth.user.username);
        } else if (ruta_enlace === "reportes") {
          verSolicitudesConOrpa();
          buscarSolicitudesRevisadasPorUsuario(auth.user.username);
        } else if (ruta_enlace === "consultar-solicitudes") {
          verSolicitudesTotales("doc");
        }
      } else {
        return history("/menu");
      }
    }
  }, [idSolicitud]);

  const limpiarTablaLog = async () => {
    await axios
      .get(`${process.env.REACT_APP_URL}/eliminar-orpas-con-gestos/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {});
  };

  const buscarSolicitudesRevisadasPorUsuario = async (e) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL}/autorizadores-cumplido-revisado/?rol_autorizador=${e}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setSolicitudesPorInterventor(response.data);
      });
  };

  const verSolicitudes = async (estado) => {
    let busqueda;
    if (estado) {
      busqueda = "PENDIENTE";
    } else {
      busqueda = "TODO";
    }
    await axios
      .get(
        `${process.env.REACT_APP_URL}/solicitudes-para-revisar/?tipo=${busqueda}&fecha_inicio=${fechaFiltrarLista.fecha_inicio}&fecha_fin=${fechaFiltrarLista.fecha_fin}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setRequest(response.data);
      });
  };
  const verSolicitudesTotales = async (boton) => {
    let busqueda;
    if (boton === "doc") {
      busqueda = `identificacion=${identificacionEstudiante}`;
    } else if (boton === "total") {
      busqueda = `fecha_inicio=${fechaFiltrarLista.fecha_inicio}&fecha_fin=${fechaFiltrarLista.fecha_fin}`;
    }
    await axios
      .get(`${process.env.REACT_APP_URL}/solicitudes-totales/?${busqueda}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setRequest(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verSolicitudesRevisadas = async () => {
    let busqueda = `?fecha_inicio=${fechaFiltrarLista.fecha_inicio}&fecha_fin=${fechaFiltrarLista.fecha_fin}`;

    await axios
      .get(
        `${process.env.REACT_APP_URL}/solicitudes-para-cargar-orpa/${busqueda}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setRequest(response.data);
      });
  };

  const verSolicitudesConOrpa = async () => {
    let busqueda = `?fecha_inicio=${fechaFiltrarLista.fecha_inicio}&fecha_fin=${fechaFiltrarLista.fecha_fin}`;
    await axios
      .get(
        `${process.env.REACT_APP_URL}/solicitudes-para-revisar-orpa/${busqueda}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setRequest(response.data);
      });
  };
  const verSolicitudesxUser = async (correo) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_URL}/solicitudes-por-usuario/?correo_solicitante=${correo}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setRequest(response.data);
        });
    } catch (error) {
      //console.log(error);
    } finally {
    }
  };
  const verSolicitudesxInterventor = async (correo) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_URL}/solicitudes-por-interventor/?correo_interventor=${correo}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setRequest(response.data);
        });
    } catch (error) {
      //console.log(error);
    } finally {
    }
  };

  return (
    <Fragment>
      <Header />
      <Modal estado={estadoModal} setEstadoModal={setEstadoModal}>
        <WatchHistoryRequest id={idSolicitud} />
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />{" "}
      <main className="detalle">
        <div
          className="breadcrumb-class"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            marginTop: "3%",
            marginBottom: "5%",
            fontSize: "16px",
          }}
        >
          <a>Está en:</a>
          <NavLink to="/">Inicio</NavLink>/{" "}
          <NavLink to="/menu">Solicitud para Radicación de Cumplidos</NavLink>/{" "}
          <span>Ver Solicitudes</span>
        </div>
        <div className="container" style={{ marginTop: "5%" }}>
          {ruta_enlace === "consultar-solicitudes" ? (
            <>
              <form>
                <div className="row">
                  <div className="form-group col-xs-12 col-md-4 col-lg-3 col-xl-3">
                    <label>Documento de Identificación</label>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        setIdentificacionEstudiante(e.target.value);
                      }}
                      onKeyDown ={pressButtonSearchEstudiante}
                      placeholder="Documento Estudiante"
                    />
                  </div>
                  <div
                    style={{ marginTop: "1%" }}
                    className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2"
                  >
                    <button
                      type="button"
                      onClick={() => {
                        verSolicitudesTotales("doc");
                      }}
                      className="btn btn-primary mb-2"
                    >
                      Buscar Solicitudes
                    </button>
                  </div>
                  <div className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2">
                    <label>Desde</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fechaFiltrarLista.fecha_inicio}
                      onChange={(e) => {
                        setFechaFiltrarLista({
                          ...fechaFiltrarLista,
                          fecha_inicio: e.target.value,
                        });
                      }}
                      placeholder="Desde"
                    />
                  </div>
                  <div className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2">
                    <label>Hasta</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fechaFiltrarLista.fecha_fin}
                      onChange={(e) => {
                        setFechaFiltrarLista({
                          ...fechaFiltrarLista,
                          fecha_fin: e.target.value,
                        });
                      }}
                      placeholder="Hasta"
                    />
                  </div>
                  <div
                    style={{ marginTop: "1%" }}
                    className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2"
                  >
                    <label> </label>
                    <button
                      type="button"
                      onClick={() => {
                        verSolicitudesTotales("total");
                      }}
                      className="btn btn-outline-dark mb-2"
                    >
                      {"Mostrar Todo"}
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <></>
          )}

          {ruta_enlace === "lista-solicitudes" ? (
            <>
              <form>
                <div className="row">
                  <div className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2">
                    <label>Desde</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fechaDescarga.fecha_inicio}
                      onChange={(e) => {
                        setFechaDescarga({
                          ...fechaDescarga,
                          fecha_inicio: e.target.value,
                        });
                      }}
                      placeholder="Desde Descarga"
                    />
                  </div>
                  <div className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2">
                    <label>Hasta</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fechaDescarga.fecha_fin}
                      onChange={(e) => {
                        setFechaDescarga({
                          ...fechaDescarga,
                          fecha_fin: e.target.value,
                        });
                      }}
                      placeholder="Hasta Descarga"
                    />
                  </div>
                  <div
                    style={{ marginTop: "1%" }}
                    className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2"
                  >
                    <button
                      type="button"
                      onClick={descargarExcelSolicitudesRevisadas}
                      className="btn btn-primary mb-2"
                    >
                      Descargar Solicitudes
                    </button>
                  </div>
                  <div className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2">
                    <label>Desde</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fechaFiltrarLista.fecha_inicio}
                      onChange={(e) => {
                        setFechaFiltrarLista({
                          ...fechaFiltrarLista,
                          fecha_inicio: e.target.value,
                        });
                      }}
                      placeholder="Desde"
                    />
                  </div>
                  <div className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2">
                    <label>Hasta</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fechaFiltrarLista.fecha_fin}
                      onChange={(e) => {
                        setFechaFiltrarLista({
                          ...fechaFiltrarLista,
                          fecha_fin: e.target.value,
                        });
                      }}
                      placeholder="Hasta"
                    />
                  </div>
                  <div
                    style={{ marginTop: "1%" }}
                    className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2"
                  >
                    <label> </label>
                    <button
                      type="button"
                      onClick={() => {
                        setCambiarModo(!cambiarModo);
                        verSolicitudes(!cambiarModo);
                      }}
                      className="btn btn-outline-dark mb-2"
                    >
                      {cambiarModo ? "Mostrar Todo" : "Mostrar Pendientes"}
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <></>
          )}
          {ruta_enlace === "lista-solicitudes-revisadas" ||
          ruta_enlace === "cumplidos-con-orpa" || ruta_enlace === "reportes" ? (
            <>
              <form>
                <div className="row">
                  <div className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2">
                    <label>Desde</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fechaDescarga.fecha_inicio}
                      onChange={(e) => {
                        setFechaDescarga({
                          ...fechaDescarga,
                          fecha_inicio: e.target.value,
                        });
                      }}
                      placeholder="Desde"
                    />
                  </div>
                  <div className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2">
                    <label>Hasta</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fechaDescarga.fecha_fin}
                      onChange={(e) => {
                        setFechaDescarga({
                          ...fechaDescarga,
                          fecha_fin: e.target.value,
                        });
                      }}
                      placeholder="Hasta"
                    />
                  </div>
                  <div
                    style={{ marginTop: "1%" }}
                    className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2"
                  >
                    <button
                      type="button"
                      onClick={descargarExcelSolicitudesRevisadas}
                      className="btn btn-primary mb-2"
                    >
                      Descargar Solicitudes
                    </button>
                  </div>
                  <div className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2">
                    <label>Desde</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fechaFiltrarLista.fecha_inicio}
                      onChange={(e) => {
                        setFechaFiltrarLista({
                          ...fechaFiltrarLista,
                          fecha_inicio: e.target.value,
                        });
                      }}
                      placeholder="Desde"
                    />
                  </div>
                  <div className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2">
                    <label>Hasta</label>
                    <input
                      type="date"
                      className="form-control"
                      value={fechaFiltrarLista.fecha_fin}
                      onChange={(e) => {
                        setFechaFiltrarLista({
                          ...fechaFiltrarLista,
                          fecha_fin: e.target.value,
                        });
                      }}
                      placeholder="Hasta"
                    />
                  </div>
                  <div
                    style={{ marginTop: "1%" }}
                    className="form-group col-xs-12 col-md-4 col-lg-2 col-xl-2"
                  >
                    <label> </label>
                    <button
                      type="button"
                      onClick={
                        ruta_enlace === "lista-solicitudes-revisadas"
                          ? verSolicitudesRevisadas
                          : verSolicitudesConOrpa
                      }
                      className="btn btn-outline-dark mb-2"
                    >
                      {"Mostrar Todo"}
                    </button>
                  </div>
                </div>
              </form>
            </>
          ) : (
            <></>
          )}
          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table text-center"
            headerWrapperClasses="thead-dark"
            bordered
            bootstrap4
            keyField={"solicitud_id"}
            data={request}
            columns={columns}
            pagination={paginationFactory()}
            filter={filterFactory()}
          />
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};

export default ListRequest;
