import React, { useEffect, useState, Fragment } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Popover, OverlayTrigger } from "react-bootstrap";
import aprobado from "../images/tesoreria/approved.png";
import rechazado from "../images/tesoreria/rejected.png";
import Header from "./Header";
import Footer from "./Footer";
import AddResolucion from "./AddResolutions";
import AddOrpa from "./AddOrpa";
import Modal from "./Modal";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { DialogLeavingPage } from "../hooks/DialogLeavingPage";
import { useNavigatingAway } from "../hooks/useNavigatingAway";
import { Hint } from "react-autocomplete-hint";
import { useAuth } from "../context/auth/store";

const Request = (props) => {
  const history = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const { state } = location;
  const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] =
    useState(false);
  const [hintData, setHintData] = useState([]);
  const [dataDialog, setDataDialog] = useState(state !== null ? state.id : "");
  const [showDialogLeavingPage, confirmNavigation, cancelNavigation] =
    useNavigatingAway(canShowDialogLeavingPage, dataDialog);
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const [createRequest, setCreateRequest] = useState(false);
  const [checkRequest, setCheckRequest] = useState(false);
  const [checkRequestView, setCheckRequestView] = useState(false);
  const [checkRequestxUser, setCheckRequestxUser] = useState(false);
  const [checkRequestRevisarDocumentos, setCheckRequestRevisarDocumentos] =
    useState(false);
  const [checkRequestTesoreria, setCheckRequestTesoreria] = useState(false);
  const [checkRequestTesoreriaOrpa, setCheckRequestTesoreriaOrpa] =
    useState(false);
  const [verEmpresa, setVerEmpresa] = useState([]);
  const [verListaTipoResolucion, setVerListaTipoResolucion] = useState([]);
  const [verListaEmpresa, setVerListaEmpresa] = useState([]);
  const [verDependencia, setVerDependencia] = useState([]);
  const [verListaDependencia, setVerListaDependencia] = useState([]);
  const [verCiudad, setVerCiudad] = useState();
  const [listaCiudades, setListaCiudades] = useState([]);
  const [listaGeneros, setListaGeneros] = useState([]);
  const [estadoModalCancelarSolicitud, setEstadoModalCancelarSolicitud] =
    useState(false);
  const [estadoModalVerHistorial, setEstadoModalVerHistorial] = useState(false);
  const [estadoModalAnadirResolucion, setEstadoModalAnadirResolucion] =
    useState(false);
  const [estadoModalAnadirORPA, setEstadoModalAnadirORPA] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [listaFinalResoluciones, setlistaFinalResoluciones] = useState([]);
  const [listaFinalOrpas, setlistaFinalOrpas] = useState([]);
  const [dataTimeHour, setdataTimeHour] = useState([]);
  const [disabledResolucion, setDisabledResolucion] = useState("");
  const [escogerTipoRechazo, setEscogerTipoRechazo] = useState("");
  const [textoBotonAceptar, setTextoBotonAceptar] = useState("");
  const [textoBotonRechazar, setTextoBotonRechazar] = useState("");
  const [tipoVinculacion, setTipoVinculacion] = useState([
    "Becario",
    "Estudiante Auxiliar",
    "Evaluador",
    "Joven Investigador",
    "Monitor",
    "Promotor de Convivencia",
    "Otro",
  ]);
  const array = [
    hoy.getDate().toString(),
    (hoy.getMonth() + 1).toString(),
    hoy.getFullYear().toString(),
  ];
  if (array[1].length === 1) {
    array[1] = "0" + array[1];
  }
  if (array[0].length === 1) {
    array[0] = "0" + array[0];
  }
  const dateToday = array[2] + "-" + array[1] + "-" + array[0];

  const [cargoInterventor, setCargoInterventor] = useState([
    "Jefe Área",
    "Jefe Oficina",
    "Jefe División",
    "Docente Ocasional",
    "Jefe de Sección",
    "Jefe de Grupo",
    "Docente",
    "Director",
    "Director Centro",
    "Coordinador",
    "Asesor",
    "Profesional Universitario",
    "Profesor Titular",
    "Profesor Asociado",
    "Coordinador",
    "Vicerrector",
    "Decano",
    "Secretaria",
    "Secretaria Académica",
  ]);

  const listaFinalResolucionesTotal = (resolucion) => {
    setlistaFinalResoluciones(resolucion);
  };

  const listaFinalOrpasTotal = (orpa) => {
    setlistaFinalOrpas(orpa);
  };

  const [datos, setDatos] = useState({
    cumplido_id: "-",
    empresa: null,
    dependencia: null,
    fecha_solicitud: dateToday,
    nombre_solicitante: "",
    cc_solicitante: "",
    correo_solicitante: "",
    genero_solicitante: "",
    tipo_vinculacion: "",
    otro_tipo_vinculacion: "",
    cc_interventor: "",
    correo_interventor: "",
    nombre_interventor: "",
    genero_interventor: "",
    cargo_interventor: "",
    tipo_telefono_interventor: "",
    telefono_interventor: "",
    tipo_de_documento_presupuestal: "",
    no_documento_presupuestal: "",
    no_acta: "",
    pago: "",
    valor: "",
    ciudad: "",
    comentarios: "",
    estado_cumplido: "",
    enlace_documento: null,
  });

  const [respuestaTesoreria, setRespuestaTesoreria] = useState({
    solicitud_id: state !== null ? state.id : "",
    rol_autorizador: "",
    comentario_rol: "",
    tipo_autorizacion: "",
  });

  useEffect(() => {
    verEmpresas();
    setRespuestaTesoreria({
      ...respuestaTesoreria,
      rol_autorizador: auth.user.username,
    });
    if (state !== null) {
      setCanShowDialogLeavingPage(false);
      if (state.tipo === "solicitud_usuario") {
        setDisabledResolucion(true);
        setCheckRequestxUser(true);
      } else if (state.tipo === "solicitud_para_interventor") {
        setCheckRequest(true);
        setTextoBotonAceptar("Aprobar Constancia de Cumplimiento");
        setTextoBotonRechazar("Rechazar Constancia de Cumplimiento");
        setDisabledResolucion(true);
      } else if (state.tipo === "solicitud_para_revisar") {
        setDataDialog(state.id);
        setCheckRequestRevisarDocumentos(true);
        verTipologiaDeGasto();
        setTextoBotonAceptar("Aprobar para Liquidación");
        setTextoBotonRechazar("Rechazar Solicitud");
        setDisabledResolucion(true);
      } else if (state.tipo === "solicitudes_revisadas") {
        if (datos.estado_cumplido !== 3) {
          setCanShowDialogLeavingPage(true);
        }
        setDataDialog(state.id);
        consultarRegistro(state.id, auth.user.username);
        setCheckRequestTesoreria(true);
        setTextoBotonAceptar("Enviar ORPA para Aprobación");
        setTextoBotonRechazar("Rechazar Solicitud");
        setDisabledResolucion(true);
      } else if (state.tipo === "solicitud_con_orpa") {
        setTextoBotonAceptar("Aprobar para Giro");
        setTextoBotonRechazar("Rechazar Liquidación");

        setCheckRequestTesoreriaOrpa(true);
        setDisabledResolucion(true);
      } else if (state.tipo === "solicitudes_totales") {
        setCheckRequestView(true);
        setDisabledResolucion(true);
      }
      verSolicitud(state.id, state.tipo);
    } else {
      validarEstadoPagina();
      buscarSolicitante(auth.user.email);
      obtenerCiudades();
      obtenerGeneros();
      setCreateRequest(true);
      setCheckRequest(false);
      setCheckRequestxUser(false);
      setCheckRequestView(false);
      setCheckRequestTesoreria(false);
      setCheckRequestRevisarDocumentos(false);
      setCheckRequestTesoreriaOrpa(false);
      if (listaFinalResoluciones.length > 0) {
        setDisabledResolucion("");
      } else {
        setDisabledResolucion(true);
      }
    }
  }, [listaFinalResoluciones.length]);

  const validarEstadoPagina = async () => {
    auth.ismaintain((estado_pagina) => {
      console.log(estado_pagina);
      if (estado_pagina !== 0) {
        toast.error(
          "No es posible crear nuevas solicitudes en este momento.",
          {
            position: "top-center",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          return history("/menu");
        }, 8000);
      }
    });
  };

  const crearRegistro = async (id, usuario) => {
    await axios
      .post(
        `${process.env.REACT_APP_URL}/registro-solicitud-tramite/`,
        {
          solicitud_id: id,
          rol_usuario: usuario,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response2) => {
        if (response2.status == 201) {
        }
      })
      .catch((error) => {
        toast.error("Existe un usuario que está manejando la solicitud.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const consultarRegistro = async (id, us) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL}/registro-x-solicitud-tramite/?solicitud_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response2) => {
        return response2.data;
      })
      .then((response) => {
        if (response[0].estado_cumplido === 3) {
          if (response.length > 1) {
            if (response[1].rol_usuario !== us) {
              setCanShowDialogLeavingPage(false);
              toast.error(
                "El usuario " +
                  response[1].rol_usuario +
                  " está gestionando el cumplido. Volverá al menú anterior.",
                {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
              setTimeout(() => {
                return history("/lista-solicitudes-revisadas");
              }, 3500);
            }
          } else {
            crearRegistro(id, us);
          }
        } else {
          setCanShowDialogLeavingPage(false);
        }
      })
      .catch((error) => {
        /*toast.error("Ocurrió un error.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });*/
      });
  };

  const formatNumber = (n) => {
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatCurrency = (input) => {
    var input_val = input.value;
    // don't validate empty input
    if (input_val === "") {
      return;
    }

    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");

      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);

      left_side = formatNumber(left_side);

      right_side = formatNumber(right_side);
      right_side = right_side.substring(0, 2);

      input_val = "$" + left_side + "." + right_side;
    } else {
      input_val = formatNumber(input_val);
      input_val = "$" + input_val;
    }
    input.value = input_val;
    setDatos({
      ...datos,
      valor: input_val,
    });
  };

  const buscarInterventor = async (correo, cc) => {
    await axios
      .post(
        `${process.env.REACT_APP_URL}/consulta-ldap-interventor/`,
        {
          correo: correo,
          cc: cc,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setDatos({
          ...datos,
          cc_interventor: response.data.employeeNumber[0],
          correo_interventor: response.data.mail[0],
          nombre_interventor: response.data.cn[0],
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          toast.error("No se encuentra información con los datos ingresados", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (error.response.status === 401) {
          toast.error(
            "El usuario ingresado no tiene permisos para ser interventor.",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        setDatos({
          ...datos,
          cc_interventor: "",
          correo_interventor: "",
          nombre_interventor: "",
        });
      });
  };

  const buscarSolicitante = async (cor) => {
    await axios
      .post(
        `${process.env.REACT_APP_URL}/consulta-ldap/`,
        {
          correo: cor,
          cc: "",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setDatos({
          ...datos,
          cc_solicitante: response.data.employeeNumber[0],
          correo_solicitante: response.data.mail[0],
          nombre_solicitante: response.data.cn[0],
        });
      })
      .catch((error) => {});
  };

  const rechazarSolicitud = async (e) => {
    e.preventDefault();
    var datos_cancelados = {
      comentarios_respuesta_solicitud: respuestaTesoreria.comentario_rol,
      nombre_solicitante: datos.nombre_solicitante,
      nombre_interventor: datos.nombre_interventor,
      cumplido_id: datos.cumplido_id,
      correo_solicitante: datos.correo_solicitante,
      correo_interventor: datos.correo_interventor,
      tabla_tesoreria: respuestaTesoreria,
    };
    if (checkRequest) {
      datos_cancelados.estado_cumplido = 1;
    }
    if (checkRequestTesoreria || checkRequestRevisarDocumentos) {
      datos_cancelados.estado_cumplido = 6;
    }
    if (checkRequestTesoreriaOrpa) {
      if (escogerTipoRechazo === "Cancelar") {
        datos_cancelados.estado_cumplido = 6;
      } else if (escogerTipoRechazo === "Reprogramar") {
        datos_cancelados.estado_cumplido = 7;
      }
    }
    if (respuestaTesoreria.comentario_rol === "") {
      return toast.warn("El campo comentario es OBLIGATORIO.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    const message_toast = toast.loading(
      "Espera un momento mientras se cancela la solicitud..."
    );
    await axios
      .put(
        `${process.env.REACT_APP_URL}/solicitud/${state.id}`,
        datos_cancelados,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        if (escogerTipoRechazo === "Reprogramar") {
          toast.update(message_toast, {
            render: (
              <div>
                ¡Solicitud Reprogramada con Exito!
                <br />
                Será redireccionado al menú principal.
              </div>
            ),
            type: "success",
            autoClose: 5000,
            isLoading: false,
          });
          setTimeout(() => {
            return history(
              state !== null
                ? state.tipo === "solicitud_para_interventor"
                  ? "/gestionar-solicitudes"
                  : state.tipo === "solicitud_usuario"
                  ? "/mis-solicitudes"
                  : state.tipo === "solicitud_para_revisar"
                  ? "/lista-solicitudes"
                  : state.tipo === "solicitudes_revisadas"
                  ? "/lista-solicitudes-revisadas"
                  : state.tipo === "solicitud_con_orpa"
                  ? "/cumplidos-con-orpa"
                  : "/menu"
                : "/menu"
            );
          }, 4500);
        } else {
          toast.update(message_toast, {
            render: (
              <div>
                ¡Solicitud Rechazada con Exito!
                <br />
                Será redireccionado al menú principal.
              </div>
            ),
            type: "success",
            hideProgressBar: false,
            closeOnClick: true,
            autoClose: 5000,
            isLoading: false,
          });
          setTimeout(() => {
            return history(
              state !== null
                ? state.tipo === "solicitud_para_interventor"
                  ? "/gestionar-solicitudes"
                  : state.tipo === "solicitud_usuario"
                  ? "/mis-solicitudes"
                  : state.tipo === "solicitud_para_revisar"
                  ? "/lista-solicitudes"
                  : state.tipo === "solicitudes_revisadas"
                  ? "/lista-solicitudes-revisadas"
                  : state.tipo === "solicitud_con_orpa"
                  ? "/cumplidos-con-orpa"
                  : "/menu"
                : "/menu"
            );
          }, 4500);
        }
        setCanShowDialogLeavingPage(false);
      })
      .catch((error) => {
        toast.update(message_toast, {
          render: <div>Ha ocurrido un error.</div>,
          type: "error",
          autoClose: 5000,
          isLoading: false,
        });
      });
  };

  const crearSolicitud = async (e) => {
    e.preventDefault();
    var datos_enviados = { ...datos };
    const message_toast = toast.loading(
      "Espera un momento mientras se procesa el cumplido..."
    );
    try {
      var relacion_ciudad_id = listaCiudades.find(
        (element) => element.nombre_ciudad === datos.ciudad
      );
      datos_enviados.ciudad = relacion_ciudad_id.id_ciudad;
    } catch (error) {
      toast.update(message_toast, {
        render: "Tiene campos pendientes por llenar.",
        type: "error",
        autoClose: 5000,
        isLoading: false,
      });
    }
    try {
      setDisabled(true);
      var response_crear = await axios.post(
        `${process.env.REACT_APP_URL}/solicitud/`,
        { datos: datos_enviados, resoluciones: listaFinalResoluciones },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response_crear.status === 201) {
        toast.update(message_toast, {
          render: (
            <div>
              ¡Solicitud Creada Correctamente!
              <br />
              Será redireccionado al menú principal.
            </div>
          ),
          type: "success",
          autoClose: 5000,
          isLoading: false,
        });
        setTimeout(() => {
          return history(
            state !== null
              ? state.tipo === "solicitud_para_interventor"
                ? "/gestionar-solicitudes"
                : state.tipo === "solicitud_usuario"
                ? "/mis-solicitudes"
                : state.tipo === "solicitud_para_revisar"
                ? "/lista-solicitudes"
                : state.tipo === "solicitudes_revisadas"
                ? "/lista-solicitudes-revisadas"
                : state.tipo === "solicitud_con_orpa"
                ? "/cumplidos-con-orpa"
                : "/menu"
              : "/menu"
          );
        }, 3500);
      }
    } catch (error) {
      setDisabled(false);
      if (String(error.response.data) === String("VALOR")) {
        toast.update(message_toast, {
          render: "Valide el campo Valor a Pagar.",
          type: "error",
          autoClose: 5000,
          isLoading: false,
        });
      }
      if (typeof error.response.data === "object") {
        toast.update(message_toast, {
          render: "Hay campos pendientes por validar.",
          type: "error",
          autoClose: 5000,
          isLoading: false,
        });
      }
      if (String(error.response.data) === String("RESOLUCIONES")) {
        toast.update(message_toast, {
          render: "Por favor valide las resoluciones.",
          type: "error",
          autoClose: 5000,
          isLoading: false,
        });
      }
      if (String(error.response.data) === String("MANTENIMIENTO")) {
        toast.update(message_toast, {
          render: "No es posible crear nuevas solicitudes en este momento.",
          type: "error",
          autoClose: 4500,
          isLoading: false,
        });
      }

      if (error.response.status === 500) {
        toast.update(message_toast, {
          render: (
            <div>
              Ocurrió un error en el servidor, por lo tanto, no quedó aprobada
              la solicitud.
              <br />
              Intenta de nuevo o más tarde.
            </div>
          ),

          type: "error",
          autoClose: 5000,
          isLoading: false,
        });
      }
    }
  };

  const cancelarSolicitudparaUsuario = async () => {
    await axios
      .put(
        `${process.env.REACT_APP_URL}/solicitud/${state.id}`,
        {
          tabla_tesoreria: respuestaTesoreria,
          estado_cumplido: 8,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        toast.success("Solicitud Cancelada. Será dirigido al menú principal.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          return history("/mis-solicitudes");
        }, 3500);
      })
      .catch((error) => {
        toast.error("Ocurrió un error con el servidor.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const verSolicitud = async (idSolicitud, state) => {
    await axios
      .get(`${process.env.REACT_APP_URL}/solicitud/${idSolicitud}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        var aux = {};
        aux.value = response.data.valor;
        response.data.valor = formatCurrency(aux);
        setDatos(response.data);
        return response.data;
      })
      .then((response) => {
        axios
          .get(`${process.env.REACT_APP_URL}/empresas/${response.empresa}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            setVerEmpresa(response.data);
          });
        axios
          .get(
            `${process.env.REACT_APP_URL}/dependencias/${response.dependencia}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            if (state === "solicitud_para_interventor") {
              setVerListaDependencia([response.data]);
            } else {
              setVerDependencia(response.data);
            }
          });
        axios
          .get(`${process.env.REACT_APP_URL}/ciudades/${response.ciudad}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((res_ciudad) => {
            setVerCiudad(res_ciudad.data.nombre_ciudad);
          });
      });
  };

  const verEmpresas = async (e) => {
    await axios
      .get(`${process.env.REACT_APP_URL}/empresas/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => response)
      .then((data) => {
        var array_empresa = data.data;
        setVerListaEmpresa(array_empresa);
      })
      .catch((error) => {
        setVerListaEmpresa([]);
      });
  };

  const verTipologiaDeGasto = async (e) => {
    await axios
      .get(`${process.env.REACT_APP_URL}/lista-tipologias/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => response)
      .then((data) => {
        setVerListaTipoResolucion(data.data);
      })
      .catch((error) => {});
  };

  const obtenerCiudades = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_URL}/ciudades/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      var hintArray = [];
      res.data.map((a) => hintArray.push(a.nombre_ciudad));
      setListaCiudades(res.data);
      setHintData(hintArray);
    } catch (error) {
      console.log("Error cargando la lista de ciudades.");
    }
  };

  const obtenerGeneros = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/lista-genero/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setListaGeneros(res.data);
    } catch (error) {
      console.log("Error cargando la lista de generos.");
    }
  };

  const verDependencias = async (e) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL}/dependencias-por-empresa/?empresa_id=${e.target.value}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => response)
      .then((data) => {
        var array_dependencia = data.data;
        setVerListaDependencia(array_dependencia);
      })
      .catch((error) => {
        setVerListaDependencia([]);
      });
  };

  const aceptarSolicitudInterventor = async (e) => {
    e.preventDefault();
    if (datos.estado_cumplido === 2) {
      return toast.warn("No puede editar una solicitud aceptada.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    var datos_actualizados = { ...datos };
    datos_actualizados.estado_cumplido = 2;
    datos_actualizados.estado_cumplido_anterior = 0;
    datos_actualizados.tabla_tesoreria = respuestaTesoreria;

    const message_toast = toast.loading(
      "Espera un momento mientras se procesa la constancia de cumplimiento..."
    );
    setDisabled(true);
    await axios
      .put(
        `${process.env.REACT_APP_URL}/solicitud/${state.id}`,
        datos_actualizados,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        if (response.status != 200) {
          setDisabled(false);
          toast.update(message_toast, {
            render: <div>Valide los campos</div>,
            type: "error",
            autoClose: 5000,
            isLoading: false,
          });
        } else {
          toast.update(message_toast, {
            render: (
              <div>
                ¡Constancia de Cumplimiento Generada Correctamente!
                <br />
                Será redireccionado al menú principal.
              </div>
            ),
            type: "success",
            autoClose: 5000,
            isLoading: false,
          });
          setTimeout(() => {
            return history(
              state !== null
                ? state.tipo === "solicitud_para_interventor"
                  ? "/gestionar-solicitudes"
                  : "/menu"
                : "/menu"
            );
          }, 4500);
        }
      })
      .catch((error) => {
        setDisabled(false);

        if (String(error.response.data) === String("MANTENIMIENTO")) {
          return toast.update(message_toast, {
            render:
              "Se encuentra deshabilitada la opción de aceptar solicitudes.",
            type: "error",
            autoClose: 5000,
            isLoading: false,
          });
        } else {
          toast.update(message_toast, {
            render: "Ocurrió un error con el servidor.",
            type: "error",
            autoClose: 5000,
            isLoading: false,
          });
        }
      });
  };
  const aceptarSolicitudTesoreriaRevisarDocumentos = async (e) => {
    e.preventDefault();
    if (datos.estado_cumplido === 5) {
      return toast.warn("No puede editar una solicitud aprobada.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (
      !datos.no_documento_presupuestal ||
      !datos.tipo_de_documento_presupuestal ||
      !datos.no_acta
    ) {
      return toast.warn(
        "Valide los campos relacionados con los documentos presupuestales.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
    var datos_actualizados_tesoreria = {
      tabla_tesoreria: respuestaTesoreria,
      estado_cumplido: 3,
      solicitud_id: parseInt(datos.solicitud_id),
      cc_solicitante: datos.cc_solicitante,
      no_acta: datos.no_acta,
      no_documento_presupuestal: datos.no_documento_presupuestal,
      tipo_de_documento_presupuestal: datos.tipo_de_documento_presupuestal,
    };
    const message_toast = toast.loading(
      "Espera un momento mientras el cumplido cambia de estado..."
    );
    try {
      setDisabled(true);
      await axios.put(
        `${process.env.REACT_APP_URL}/solicitud/${state.id}`,
        datos_actualizados_tesoreria,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setCanShowDialogLeavingPage(false);
      toast.update(message_toast, {
        render: (
          <div>
            Los documentos han sido aprobados.
            <br />
            Será redireccionado al menú principal.
          </div>
        ),
        type: "success",
        autoClose: 5000,
        isLoading: false,
      });
      setTimeout(() => {
        return history(
          state !== null
            ? state.tipo === "solicitud_para_revisar"
              ? "/lista-solicitudes"
              : "/menu"
            : "/menu"
        );
      }, 3500);
    } catch (error) {
      setDisabled(false);
      toast.update(message_toast, {
        render: <div>Ocurrió un error al procesar el cumplido.</div>,
        type: "error",
        autoClose: 5000,
        isLoading: false,
      });
    }
  };

  const aceptarSolicitudTesoreria = async (e) => {
    e.preventDefault();
    if (datos.estado_cumplido === 5) {
      return toast.warn("No puede editar una solicitud aprobada.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    if (listaFinalOrpas.length < 1) {
      return toast.warn(
        "Recuerde añadir la orden de pago correspondiente al cumplido.",
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }

    listaFinalOrpas[0].empresa_orpa = datos.empresa;
    var datos_actualizados_tesoreria = {
      tabla_tesoreria: respuestaTesoreria,
      estado_cumplido: 4,
      solicitud_id: parseInt(datos.solicitud_id),
      cc_solicitante: datos.cc_solicitante,
      orpa: listaFinalOrpas[0],
    };
    const message_toast = toast.loading(
      "Espera un momento mientras se carga la Orden de Pago..."
    );
    try {
      setDisabled(true);
      await axios.put(
        `${process.env.REACT_APP_URL}/solicitud/${state.id}`,
        datos_actualizados_tesoreria,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setCanShowDialogLeavingPage(false);
      toast.update(message_toast, {
        render: (
          <div>
            Orden de Pago Guardada Correctamente
            <br />
            Será redireccionado al menú principal.
          </div>
        ),
        type: "success",
        autoClose: 5000,
        isLoading: false,
      });
      setTimeout(() => {
        return history(
          state !== null
            ? state.tipo === "solicitudes_revisadas"
              ? "/lista-solicitudes-revisadas"
              : "/menu"
            : "/menu"
        );
      }, 3500);
      await axios.delete(
        `${process.env.REACT_APP_URL}/registro-solicitud-tramite/${state.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      setDisabled(false);
      toast.update(message_toast, {
        render: <div>Ocurrió un error al procesar el cumplido.</div>,
        type: "error",
        autoClose: 5000,
        isLoading: false,
      });
    }
  };
  const aceptarSolicitudTesoreriaSegundoPaso = async (e) => {
    e.preventDefault();
    if (datos.estado_cumplido === 5) {
      return toast.warn("No puede editar una solicitud aprobada.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    var datos_actualizados_tesoreria = { ...datos };
    datos_actualizados_tesoreria.tabla_tesoreria = respuestaTesoreria;
    datos_actualizados_tesoreria.estado_cumplido = 5;

    const message_toast = toast.loading(
      "Espera un momento mientras se procesa el cumplido..."
    );
    setDisabled(true);
    await axios
      .put(
        `${process.env.REACT_APP_URL}/solicitud/${state.id}`,
        datos_actualizados_tesoreria,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        if (response.status != 200) {
          setDisabled(false);
          toast.warn("Valide los campos", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.update(message_toast, {
            render: (
              <div>
                ¡Cumplido Aprobado Correctamente!
                <br />
                Será redireccionado al menú principal.
              </div>
            ),
            type: "success",
            autoClose: 5000,
            isLoading: false,
          });
          setTimeout(() => {
            return history(
              state !== null
                ? state.tipo === "solicitud_con_orpa"
                  ? "/cumplidos-con-orpa"
                  : "/menu"
                : "/menu"
            );
          }, 4500);
        }
      })
      .catch((error) => {
        setDisabled(false);
        if (error.response.status === 400) {
          toast.update(message_toast, {
            render: "Ocurrió un error con el servidor.",
            type: "error",
            autoClose: 5000,
            isLoading: false,
          });
        }
      });
  };

  return (
    <Fragment>
      <Header />
      <DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />{" "}
      <main className="detalle">
        <div
          className="breadcrumb-class"
          style={{
            display: "block",
            marginLeft: "auto !important",
            marginRight: "auto",
            marginTop: "3%",
            marginBottom: "5%",
            fontSize: "16px !important",
          }}
        >
          <a>Está en:</a>
          <NavLink to="/">Inicio</NavLink>/{" "}
          <NavLink
            to={
              state !== null
                ? state.tipo === "solicitud_para_interventor"
                  ? "/gestionar-solicitudes"
                  : state.tipo === "solicitud_usuario"
                  ? "/mis-solicitudes"
                  : state.tipo === "solicitud_para_revisar"
                  ? "/lista-solicitudes"
                  : state.tipo === "solicitudes_revisadas"
                  ? "/lista-solicitudes-revisadas"
                  : state.tipo === "solicitud_con_orpa"
                  ? "/cumplidos-con-orpa"
                  : state.tipo === "solicitudes_totales"
                  ? "/consultar-solicitudes"
                  : "/menu"
                : "/menu"
            }
          >
            Radicación solicitud de pago
          </NavLink>
          / <span>{createRequest ? "Crear Solicitud" : "Ver Solicitud"}</span>
        </div>
        <div className="row justify-content-md-center">
          <div
            className="col-xs-9 col-sm-9 col-md-9 col-lg-9 col-xl-9"
            id="titulologin"
          >
            <h2>
              {createRequest
                ? "Información detallada de la solicitud"
                : "Ver Solicitudes de Pago"}
            </h2>

            <h4>
              {checkRequest
                ? "Valida la información y responde la solicitud."
                : checkRequestxUser ||
                  checkRequestRevisarDocumentos ||
                  checkRequestTesoreria ||
                  checkRequestTesoreriaOrpa
                ? "Información detallada de la solicitud"
                : ""}
            </h4>
            {createRequest ? (
              <>
                {" "}
                <div>
                  <br></br>
                  <a>Para tener en cuenta:</a>
                  <br></br>
                  <a>
                    {" "}
                    1. Primero debe adjuntar la(s) resolución(es) que tengan
                    relación con el(los) pago(s) que va a solicitar, para que se
                    habiliten los campos que deben ser diligenciados. En caso de
                    modificación, adición o prórroga se deben adjuntar las
                    respectivas resoluciones.
                  </a>
                  <br></br>
                  <a>
                    {" "}
                    2. El formulario está compuesto por tres partes: <br></br>
                    <strong>a.</strong> Información del estudiante. <br></br>
                    <strong>b.</strong> Datos del responsable del seguimiento
                    definido en la resolución. <br></br>
                    <strong>c. </strong>
                    Datos del pago.
                  </a>
                  <br></br>
                  <a>
                    3. Si va a solicitar dos o más pagos debe diligenciar un
                    formulario por cada pago de forma individual.
                  </a>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <br></br>
          <br style={{ marginBottom: "2%" }}></br>
          {datos.estado_cumplido === 5 ? (
            <>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <img alt="aprobado" src={aprobado} />
              </div>
            </>
          ) : null}
          {datos.estado_cumplido === 1 || datos.estado_cumplido === 8 ? (
            <>
              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                <img alt="rechazado" src={rechazado} />
              </div>
            </>
          ) : null}
        </div>
        <div>
          <div className="row justify-content-md-center">
            <div className="col-xs-10 col-sm-10 col-md-10 col-lg-9 col-xl-9">
              <div className="row" style={{ marginBottom: "2%" }}>
                <div className="col col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                  <br></br>
                  <br></br>
                </div>
                {(checkRequestTesoreria || checkRequestTesoreriaOrpa) &&
                (datos.estado_cumplido === 3 ||
                  datos.estado_cumplido === 4 ||
                  datos.estado_cumplido === 5) ? (
                  <>
                    <div className="col col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                      <button
                        type="button"
                        className="btn btn-warning btn-lg"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg"
                        onClick={() => {
                          if (estadoModalCancelarSolicitud === true) {
                            setEstadoModalCancelarSolicitud(
                              !estadoModalCancelarSolicitud
                            );
                          }
                          if (estadoModalAnadirResolucion === true) {
                            setEstadoModalAnadirResolucion(
                              !estadoModalAnadirResolucion
                            );
                          }
                          if (estadoModalAnadirORPA === false) {
                            setEstadoModalAnadirORPA(!estadoModalAnadirORPA);
                          }
                        }}
                        disabled={isDisabled}
                      >
                        {datos.estado_cumplido === 3
                          ? "Adjuntar Orden de Pago"
                          : datos.estado_cumplido === 4 ||
                            datos.estado_cumplido === 5
                          ? "Ver Orden de Pago"
                          : "No aplica"}
                      </button>
                    </div>
                  </>
                ) : null}
                {datos.enlace_documento !== null ? (
                  <>
                    <div className="col col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
                      <a
                        type="button"
                        className="btn btn-info btn-lg"
                        href={datos.enlace_documento}
                        target="_blank"
                      >
                        Ver Cumplido
                      </a>
                    </div>
                  </>
                ) : null}
                {createRequest ? (
                  <>
                    <div
                      className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4"
                      style={{ marginTop: "2%" }}
                    >
                      <button
                        type="button"
                        className="btn btn-warning btn-lg"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg"
                        onClick={() => {
                          if (estadoModalCancelarSolicitud === true) {
                            setEstadoModalCancelarSolicitud(
                              !estadoModalCancelarSolicitud
                            );
                          }
                          if (estadoModalAnadirResolucion === false) {
                            setEstadoModalAnadirResolucion(
                              !estadoModalAnadirResolucion
                            );
                          }
                          if (estadoModalAnadirORPA === true) {
                            setEstadoModalAnadirORPA(!estadoModalAnadirORPA);
                          }
                        }}
                      >
                        Añadir Resoluciones
                      </button>
                    </div>
                  </>
                ) : null}
                <br></br>
              </div>

              <form>
                <div className="row">
                  {checkRequestRevisarDocumentos ||
                  checkRequestTesoreria ||
                  checkRequestTesoreriaOrpa ? (
                    <>
                      <div className="form-group col-xs-6 col-md-4 col-lg-4">
                        <label>Tipo de documento presupuestal</label>
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          defaultValue={null}
                          onChange={(e) => {
                            setDatos({
                              ...datos,
                              tipo_de_documento_presupuestal: e.target.value,
                            });
                          }}
                          value={datos.tipo_de_documento_presupuestal}
                          disabled={
                            checkRequestRevisarDocumentos &&
                            datos.estado_cumplido === 2
                              ? ""
                              : disabledResolucion
                          }
                        >
                          <option value={null}>Seleccionar...</option>
                          {checkRequestxUser ||
                          checkRequestTesoreria ||
                          checkRequestTesoreriaOrpa ? (
                            <option
                              value={datos.tipo_de_documento_presupuestal}
                            >
                              {datos.tipo_de_documento_presupuestal}
                            </option>
                          ) : (
                            verListaTipoResolucion.map((element) => (
                              <option value={element.sigla_tipologia}>
                                {element.sigla_tipologia +
                                  " - " +
                                  element.nom_tipologia}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                      <div className="form-group col-xs-6 col-md-4 col-lg-4">
                        <label>No. de documento presupuestal</label>
                        <input
                          type="text"
                          className="form-control"
                          id="noDocPresupuestal"
                          name="noDocPresupuestal"
                          onChange={(e) =>
                            setDatos({
                              ...datos,
                              no_documento_presupuestal: e.target.value,
                            })
                          }
                          value={datos.no_documento_presupuestal}
                          disabled={
                            checkRequestRevisarDocumentos &&
                            datos.estado_cumplido === 2
                              ? ""
                              : disabledResolucion
                          }
                        />
                      </div>
                      <div className="form-group col-xs-6 col-md-4 col-lg-4">
                        <label>No. de acta</label>
                        <input
                          type="text"
                          className="form-control"
                          id="noActa"
                          name="noActa"
                          onChange={(e) =>
                            setDatos({
                              ...datos,
                              no_acta: e.target.value,
                            })
                          }
                          value={datos.no_acta}
                          disabled={
                            checkRequestRevisarDocumentos &&
                            datos.estado_cumplido === 2
                              ? ""
                              : disabledResolucion
                          }
                        />
                      </div>
                    </>
                  ) : null}

                  {!createRequest ? (
                    <>
                      <div className="form-group col-xs-6 col-md-6 col-lg-3">
                        <label>Id Solicitud</label>
                        <input
                          type="text"
                          className="form-control"
                          id="idSolicitud"
                          name="idSolicitud"
                          onChange={(e) =>
                            setDatos({ ...datos, cumplido_id: e.target.value })
                          }
                          value={datos.cumplido_id}
                          disabled
                        />
                      </div>
                    </>
                  ) : null}
                  <div
                    className={
                      !createRequest
                        ? "form-group col-xs-6 col-md-6 col-lg-3 col-xl-3"
                        : "form-group col-xs-6 col-md-12 col-lg-6 col-xl-3"
                    }
                  >
                    <label>Fecha de Solicitud</label>
                    <input
                      type="date"
                      className="form-control"
                      id="fechaSolicitud"
                      name="fechaSolicitud"
                      value={
                        createRequest
                          ? datos.fecha_solicitud
                          : datos.fecha_solicitud.split("T")[0]
                      }
                      disabled
                    />
                  </div>
                  <div className="form-group col-xs-12 col-md-6 col-lg-6 col-xl-3">
                    <label>Beneficiario del Pago</label>
                    <font color="red">*</font>
                    <input
                      type="text"
                      className="form-control"
                      id="areaDependencia"
                      name="areaDependencia"
                      onChange={(e) =>
                        setDatos({
                          ...datos,
                          nombre_solicitante: e.target.value,
                        })
                      }
                      value={
                        createRequest
                          ? auth.user.first_name + " " + auth.user.last_name
                          : datos.nombre_solicitante
                      }
                      disabled
                    />
                  </div>
                  <div className="form-group col-xs-12 col-md-3 col-lg-6 col-xl-3">
                    {createRequest ? (
                      <>
                        <label>Género</label>
                        <font color="red">*</font>
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          defaultValue="Seleccionar..."
                          onChange={(e) => {
                            setDatos({
                              ...datos,
                              genero_solicitante: e.target.value,
                            });
                          }}
                          value={datos.genero_solicitante}
                          disabled={disabledResolucion}
                        >
                          {createRequest ? (
                            <option>Seleccionar...</option>
                          ) : (
                            <></>
                          )}
                          {createRequest ? (
                            listaGeneros.map((element) => (
                              <option value={element}>{element}</option>
                            ))
                          ) : (
                            <option value={datos.genero_solicitante}>
                              {datos.genero_solicitante}
                            </option>
                          )}
                        </select>
                      </>
                    ) : (
                      <>
                        <label>No. Identificación Solicitante</label>
                        <font color="red">*</font>
                        <input
                          type={"text"}
                          pattern={"[0-9]+"}
                          maxLength="10"
                          className="form-control"
                          id="docSolicitante"
                          name="docSolicitante"
                          autocomplete="nope"
                          value={datos.cc_solicitante}
                          disabled={disabledResolucion}
                        />
                      </>
                    )}{" "}
                  </div>
                  <div className="form-group col-xs-12 col-md-3 col-lg-6 col-xl-3">
                    <label>Tipo de Vinculación</label>
                    <font color="red">*</font>
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      defaultValue="Seleccionar..."
                      onChange={(e) => {
                        setDatos({
                          ...datos,
                          tipo_vinculacion: e.target.value,
                        });
                      }}
                      value={datos.tipo_vinculacion}
                      disabled={
                        checkRequest && datos.estado_cumplido === 0
                          ? ""
                          : disabledResolucion
                      }
                    >
                      {checkRequest === false || checkRequestxUser === false ? (
                        <option>Seleccionar...</option>
                      ) : (
                        ""
                      )}
                      {checkRequest === true || checkRequestxUser === true ? (
                        <option value={datos.tipo_vinculacion}>
                          {datos.tipo_vinculacion}
                        </option>
                      ) : (
                        tipoVinculacion.map((element) => (
                          <option value={element}>{element}</option>
                        ))
                      )}
                    </select>{" "}
                  </div>
                  {datos.tipo_vinculacion === "Otro" ? (
                    <>
                      <div
                        className={
                          "form-group col-xs-12 col-md-3 col-lg-3 col-xl-3"
                        }
                      >
                        <label>
                          Detalle del tipo de vinculación{" "}
                          <font color="red">*</font>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="detallePago"
                          name="detallePago"
                          onChange={(e) =>
                            setDatos({
                              ...datos,
                              otro_tipo_vinculacion: e.target.value,
                            })
                          }
                          value={datos.otro_tipo_vinculacion}
                          disabled={
                            checkRequest && datos.estado_cumplido === 0
                              ? ""
                              : disabledResolucion
                          }
                        />
                      </div>
                      <div
                        className={
                          !createRequest
                            ? "form-group col-xs-12 col-md-6 col-lg-6 col-xl-6"
                            : "form-group col-xs-12 col-md-3 col-lg-3 col-xl-3"
                        }
                      >
                        <label>Empresa QUIPU</label>
                        <font color="red">*</font>{" "}
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Popover id="popover-basic">
                              <Popover.Header as="h3">
                                Empresa QUIPÚ
                              </Popover.Header>
                              <Popover.Body>
                                Este dato se encuentra dentro del contenido de
                                la resolución de autorización del gasto.
                                Corresponde a la unidad ejecutora de la cual
                                provienes recursos presupuestales para el
                                estímulo económico.
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <select
                            className="form-control"
                            aria-label="Default select example"
                            defaultValue="Seleccionar..."
                            onChange={(e) => {
                              setDatos({ ...datos, empresa: e.target.value });
                              verDependencias(e);
                            }}
                            value={datos.empresa}
                            disabled={
                              checkRequest && datos.estado_cumplido === 0
                                ? ""
                                : disabledResolucion
                            }
                          >
                            <option>Seleccionar...</option>
                            {checkRequestxUser ||
                            checkRequestRevisarDocumentos ||
                            checkRequestTesoreria ||
                            checkRequestTesoreriaOrpa ? (
                              <option value={verEmpresa.empresa_id}>
                                {verEmpresa.empresa_id +
                                  " - " +
                                  verEmpresa.nom_facultad}
                              </option>
                            ) : (
                              verListaEmpresa.map((element) => (
                                <option value={element.empresa_id}>
                                  {element.empresa_id +
                                    " - " +
                                    element.nom_facultad}
                                </option>
                              ))
                            )}
                          </select>
                        </OverlayTrigger>
                      </div>

                      <div
                        className={
                          !createRequest
                            ? "form-group col-xs-12 col-md-12 col-lg-12 col-xl-12"
                            : "form-group col-xs-12 col-md-6 col-lg-6 col-xl-6"
                        }
                      >
                        <label>
                          Dependencia en la que se encuentra vinculado
                        </label>
                        <font color="red">*</font>
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          defaultValue="0000"
                          onChange={(e) =>
                            setDatos({ ...datos, dependencia: e.target.value })
                          }
                          value={datos.dependencia}
                          disabled={
                            checkRequest && datos.estado_cumplido === 0
                              ? ""
                              : disabledResolucion
                          }
                        >
                          {checkRequest || createRequest ? (
                            <>
                              <option value="0000">Seleccionar...</option>
                            </>
                          ) : (
                            <></>
                          )}
                          {checkRequestxUser ||
                          checkRequestTesoreria ||
                          checkRequestRevisarDocumentos ||
                          checkRequestView ||
                          checkRequestTesoreriaOrpa ? (
                            <option value={verDependencia.dependencia_id}>
                              {verDependencia.nom_dependencia}
                            </option>
                          ) : (
                            verListaDependencia.map((element) => (
                              <option value={element.dependencia_id}>
                                {element.nom_dependencia}
                              </option>
                            ))
                          )}
                        </select>{" "}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={
                          !createRequest
                            ? "form-group col-xs-12 col-md-6 col-lg-6 col-xl-3"
                            : "form-group col-xs-12 col-md-6 col-lg-6 col-xl-6"
                        }
                      >
                        <label>Empresa QUIPU</label>
                        <font color="red">*</font>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Popover id="popover-basic">
                              <Popover.Header as="h3">
                                Empresa QUIPÚ
                              </Popover.Header>
                              <Popover.Body>
                                Este dato se encuentra dentro del contenido de
                                la resolución de autorización del gasto.
                                Corresponde a la unidad ejecutora de la cual
                                provienes recursos presupuestales para el
                                estímulo económico.
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <select
                            className="form-control"
                            aria-label="Default select example"
                            defaultValue="Seleccionar..."
                            onChange={(e) => {
                              setDatos({ ...datos, empresa: e.target.value });
                              verDependencias(e);
                            }}
                            value={datos.empresa}
                            disabled={
                              checkRequest && datos.estado_cumplido === 0
                                ? ""
                                : disabledResolucion
                            }
                          >
                            <option>Seleccionar...</option>
                            {checkRequestxUser ||
                            checkRequestTesoreria ||
                            checkRequestRevisarDocumentos ||
                            checkRequestTesoreriaOrpa ? (
                              <option value={verEmpresa.empresa_id}>
                                {verEmpresa.empresa_id +
                                  " - " +
                                  verEmpresa.nom_facultad}
                              </option>
                            ) : (
                              verListaEmpresa.map((element) => (
                                <option value={element.empresa_id}>
                                  {element.empresa_id +
                                    " - " +
                                    element.nom_facultad}
                                </option>
                              ))
                            )}
                          </select>
                        </OverlayTrigger>
                      </div>
                      <div
                        className={
                          "form-group col-xs-12 col-md-6 col-lg-6 col-xl-6"
                        }
                      >
                        <label>
                          Dependencia en la que se encuentra vinculado
                        </label>
                        <font color="red">*</font>
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          defaultValue="0000"
                          onChange={(e) =>
                            setDatos({ ...datos, dependencia: e.target.value })
                          }
                          value={datos.dependencia}
                          disabled={
                            checkRequest && datos.estado_cumplido === 0
                              ? ""
                              : disabledResolucion
                          }
                        >
                          {checkRequest || createRequest ? (
                            <>
                              <option value="0000">Seleccionar...</option>
                            </>
                          ) : (
                            <></>
                          )}
                          {checkRequestxUser ||
                          checkRequestTesoreria ||
                          checkRequestRevisarDocumentos ||
                          checkRequestView ||
                          checkRequestTesoreriaOrpa ? (
                            <option value={verDependencia.dependencia_id}>
                              {verDependencia.nom_dependencia}
                            </option>
                          ) : (
                            verListaDependencia.map((element) => (
                              <option value={element.dependencia_id}>
                                {element.nom_dependencia}
                              </option>
                            ))
                          )}
                        </select>{" "}
                      </div>
                    </>
                  )}

                  <div className="form-group col-xs-12 col-md-12 col-lg-12 col-xl-12">
                    {" "}
                    <h6>
                      Datos del responsable del seguimiento definido en la
                      resolución
                    </h6>
                  </div>
                  <div className="form-group col-xs-12 col-md-6 col-lg-6 col-xl-3">
                    <label>
                      No. Identificación <font color="red">*</font>
                    </label>
                    <input
                      type={"text"}
                      pattern={"[0-9]+"}
                      maxLength="10"
                      className="form-control"
                      id="docInterventor"
                      name="docInterventor"
                      autocomplete="nope"
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setDatos({
                            ...datos,
                            cc_interventor: e.target.value,
                          });
                        }
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== "") {
                          buscarInterventor("", e.target.value);
                        }
                      }}
                      value={datos.cc_interventor}
                      disabled={disabledResolucion}
                    />
                  </div>
                  <div className="form-group col-xs-12 col-md-6 col-lg-6 col-xl-3">
                    <label>
                      Correo electrónico
                      <font color="red">*</font>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="correoInterventor"
                      name="correoInterventor"
                      autocomplete="nope"
                      onChange={(e) => {
                        setDatos({
                          ...datos,
                          correo_interventor: e.target.value,
                        });
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== "") {
                          buscarInterventor(e.target.value, "");
                        }
                      }}
                      value={datos.correo_interventor}
                      disabled={disabledResolucion}
                    />
                  </div>

                  <div className="form-group col-xs-12 col-md-6 col-lg-6 col-xl-6">
                    <label>
                      Nombre Completo
                      <font color="red">*</font>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) =>
                        setDatos({
                          ...datos,
                          nombre_interventor: e.target.value,
                        })
                      }
                      value={datos.nombre_interventor}
                      disabled={"true"}
                    />
                  </div>
                  <div className="form-group col-xs-12 col-md-3  col-lg-6 col-xl-3">
                    <label>Género</label>
                    <font color="red">*</font>
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      defaultValue="Seleccionar..."
                      onChange={(e) => {
                        setDatos({
                          ...datos,
                          genero_interventor: e.target.value,
                        });
                      }}
                      value={datos.genero_interventor}
                      disabled={disabledResolucion}
                    >
                      {createRequest ? <option>Seleccionar...</option> : <></>}
                      {createRequest ? (
                        listaGeneros.map((element) => (
                          <option value={element}>{element}</option>
                        ))
                      ) : (
                        <option value={datos.genero_interventor}>
                          {datos.genero_interventor}
                        </option>
                      )}{" "}
                    </select>{" "}
                  </div>

                  <div className="form-group col-xs-12 col-md-3 col-lg-6 col-xl-3">
                    <label>
                      Cargo
                      <font color="red">*</font>
                    </label>
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      id="cargoInterventor"
                      name="cargoInterventor"
                      defaultValue=""
                      onChange={(e) => {
                        setDatos({
                          ...datos,
                          cargo_interventor: e.target.value,
                        });
                      }}
                      value={datos.cargo_interventor}
                      disabled={
                        checkRequest && datos.estado_cumplido === 0
                          ? ""
                          : disabledResolucion
                      }
                    >
                      <option value={""}>Seleccionar...</option>
                      {checkRequestxUser ||
                      checkRequestRevisarDocumentos ||
                      checkRequestTesoreria ||
                      checkRequestTesoreriaOrpa ? (
                        <option value={datos.cargo_interventor}>
                          {datos.cargo_interventor}
                        </option>
                      ) : (
                        cargoInterventor.map((element) => (
                          <option value={element}>{element}</option>
                        ))
                      )}
                    </select>{" "}
                  </div>
                  <div className="form-group col-xs-12 col-md-6 col-lg-3 col-xl-3">
                    <label>Teléfono de contacto</label>
                    <font color="red">*</font>
                    <select
                      className="form-control"
                      aria-label="Default select example"
                      defaultValue="Seleccionar..."
                      onChange={(e) => {
                        setDatos({
                          ...datos,
                          tipo_telefono_interventor: e.target.value,
                        });
                      }}
                      value={datos.tipo_telefono_interventor}
                      disabled={
                        checkRequest && datos.estado_cumplido === 0
                          ? ""
                          : disabledResolucion
                      }
                    >
                      <option>Seleccionar...</option>
                      <option>Ext. Tel. UNAL</option>
                      <option>Celular</option>
                    </select>{" "}
                  </div>
                  <div className="form-group col-xs-12 col-md-6 col-lg-3 col-xl-3">
                    <label>
                      Número<font color="red">*</font>
                    </label>
                    <input
                      type="text"
                      pattern={"[0-9]+"}
                      maxLength="10"
                      className="form-control"
                      id="extensionInterventor"
                      name="extensionInterventor"
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setDatos({
                            ...datos,
                            telefono_interventor: e.target.value,
                          });
                        }
                      }}
                      value={datos.telefono_interventor}
                      disabled={
                        checkRequest && datos.estado_cumplido === 0
                          ? ""
                          : disabledResolucion
                      }
                    />
                  </div>
                  <div className="form-group col-xs-12 col-md-12 col-lg-12 col-xl-12">
                    {" "}
                    <h6>Datos del pago</h6>
                  </div>

                  <div className="form-group col-xs-12 col-md-4 col-lg-4 col-xl-4">
                    <label>
                      Número de Pago <font color="red">*</font>
                    </label>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Header as="h3">
                            Número de Pago
                          </Popover.Header>
                          <Popover.Body>
                            Corresponde al número de pago que se está
                            solicitando autorizar. Ejemplo: Pago 1, de los 5
                            pagos autorizados en la resolución.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <input
                        type="number"
                        min="1"
                        max="24"
                        className="form-control"
                        id="numPago"
                        name="numPago"
                        onChange={(e) => {
                          if (!isNaN(e.target.value)) {
                            if (e.target.value < 25) {
                              setDatos({
                                ...datos,
                                pago: e.target.value,
                              });
                            }
                          }
                        }}
                        value={datos.pago}
                        disabled={
                          checkRequest && datos.estado_cumplido === 0
                            ? ""
                            : disabledResolucion
                        }
                        autoComplete="none"
                      />
                    </OverlayTrigger>
                  </div>
                  <div className="form-group col-xs-12 col-md-4 col-lg-4 col-xl-4">
                    <label>
                      Valor a Pagar <font color="red">*</font>
                    </label>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Header as="h3">Valor a Pagar</Popover.Header>
                          <Popover.Body>
                            Corresponde al valor parcial que se está solicitando
                            autorizar. Ejemplo: $500.000, de los 5 pagos
                            parciales por $500.000 c/u autorizados en la
                            resolución.
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <input
                        type="text"
                        pattern={"[0-9]+"}
                        maxLength="13"
                        className="form-control"
                        id="valorPagar"
                        name="valorPagar"
                        onChange={(e) => {
                          formatCurrency(e.target);
                        }}
                        value={datos.valor}
                        disabled={
                          checkRequest && datos.estado_cumplido === 0
                            ? ""
                            : disabledResolucion
                        }
                      />
                    </OverlayTrigger>
                  </div>
                  <div className="form-group col-xs-6 col-md-4 col-lg-4 col-xl-4">
                    <label>Ciudad de Ejecución</label>
                    <font color="red">*</font>
                    <Hint options={hintData} allowTabFill>
                      <input
                        className="form-control input-with-hint"
                        id="ciudadEjecucion"
                        name="ciudadEjecucion"
                        value={createRequest ? datos.ciudad : verCiudad}
                        disabled={disabledResolucion}
                        onChange={(e) =>
                          setDatos({
                            ...datos,
                            ciudad: e.target.value.toUpperCase(),
                          })
                        }
                        onBlur={(e) => {
                          if (
                            !hintData.includes(datos.ciudad) &&
                            datos.ciudad !== ""
                          ) {
                            setDatos({
                              ...datos,
                              ciudad: "",
                            });

                            toast.error(
                              "La ciudad no se encuentra registrada.",
                              {
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              }
                            );
                          }
                        }}
                        autocomplete="nope"
                      />
                    </Hint>
                  </div>
                  {!createRequest ? (
                    <>
                      <div className="form-group col-xs-12 col-md-12 col-lg-12">
                        <label>
                          Información adicional relacionada con la constancia de
                          cumplimiento.
                        </label>
                        <textarea
                          className="form-control"
                          id="comentarioCumplido"
                          maxlength="600"
                          rows="2"
                          onChange={(e) =>
                            setDatos({ ...datos, comentarios: e.target.value })
                          }
                          value={datos.comentarios}
                          disabled={
                            checkRequest && datos.estado_cumplido === 0
                              ? ""
                              : disabledResolucion
                          }
                        ></textarea>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <Modal
                    estado={estadoModalCancelarSolicitud}
                    setEstadoModal={setEstadoModalCancelarSolicitud}
                  >
                    <div>
                      <div className="modal-header">
                        <h4 className="modal-title">
                          {checkRequestxUser === true
                            ? "Cancelar Solicitud"
                            : "Rechazar Solicitud"}
                        </h4>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        {checkRequestTesoreriaOrpa ? (
                          <>
                            <div>
                              <div className="container mb-3">
                                <div className="form-check-inline col col-xs-5 col-md-5 col-lg-5">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="cancelarOpciones"
                                    id="inlineRadio1"
                                    value="Cancelar"
                                    onChange={() => {
                                      setEscogerTipoRechazo("Cancelar");
                                    }}
                                  />
                                  <label className="form-check-label">
                                    Cancelar solicitud totalmente.
                                  </label>
                                </div>
                                <div className="form-check-inline col col-xs-5 col-md-5 col-lg-5">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="cancelarOpciones"
                                    id="inlineRadio2"
                                    value="Reprogramar"
                                    onChange={() => {
                                      setEscogerTipoRechazo("Reprogramar");
                                    }}
                                  />
                                  <label className="form-check-label">
                                    Devolver solicitud a revisión por parte de
                                    tesorería.
                                  </label>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="mb-3">
                          <label>
                            {" "}
                            {checkRequestxUser
                              ? "Redacte el motivo por el cuál cancela la solicitud de cumplido."
                              : checkRequest
                              ? "Redacte el motivo por el cuál rechaza la solicitud de cumplido."
                              : checkRequestTesoreria ||
                                checkRequestRevisarDocumentos ||
                                checkRequestTesoreriaOrpa
                              ? "Redacte el motivo por el cuál rechaza el cumplido."
                              : ""}
                            <font color="red">*</font>
                          </label>
                          <textarea
                            className="form-control"
                            id="comentarioCancelar"
                            maxlength="500"
                            rows="3"
                            onChange={
                              checkRequestRevisarDocumentos ||
                              checkRequestTesoreria ||
                              checkRequestTesoreriaOrpa ||
                              checkRequest
                                ? (e) =>
                                    setRespuestaTesoreria({
                                      ...respuestaTesoreria,
                                      comentario_rol: e.target.value,
                                    })
                                : ""
                            }
                          ></textarea>
                        </div>
                      </div>
                      <button
                        className="btn btn-dark btn-lg btn-block"
                        type="button"
                        data-dismiss="modal"
                        onClick={rechazarSolicitud}
                      >
                        Enviar
                      </button>
                    </div>
                  </Modal>
                  <Modal
                    estado={estadoModalAnadirResolucion}
                    setEstadoModal={setEstadoModalAnadirResolucion}
                  >
                    <AddResolucion
                      fechaHora={dataTimeHour}
                      state={state === null ? state : state.tipo}
                      estadoCumplido={datos.estado_cumplido}
                      arregloFinalResoluciones={listaFinalResoluciones}
                      id={state !== null ? state.id : state}
                      listaFinalResoluciones={listaFinalResolucionesTotal}
                    />
                  </Modal>
                  <Modal
                    estado={estadoModalAnadirORPA}
                    setEstadoModal={setEstadoModalAnadirORPA}
                  >
                    <AddOrpa
                      fechaHora={dataTimeHour}
                      state={state === null ? state : state.tipo}
                      estadoCumplido={datos.estado_cumplido}
                      arregloFinalOrpas={listaFinalOrpas}
                      id={state !== null ? state.id : state}
                      listaFinalOrpas={listaFinalOrpasTotal}
                    />
                  </Modal>
                  {checkRequestxUser ? (
                    datos.estado_cumplido === 0 ? (
                      <>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <button
                            type="button"
                            className="btn btn-danger btn-lg btn-block"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (checkRequestxUser) {
                                cancelarSolicitudparaUsuario();
                              } else {
                                if (estadoModalCancelarSolicitud === false) {
                                  setEstadoModalCancelarSolicitud(
                                    !estadoModalCancelarSolicitud
                                  );
                                }
                              }
                            }}
                          >
                            Cancelar Solicitud
                          </button>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <button
                            type="button"
                            className="btn btn-warning btn-lg btn-block"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (estadoModalCancelarSolicitud === true) {
                                setEstadoModalCancelarSolicitud(
                                  !estadoModalCancelarSolicitud
                                );
                              }
                              if (estadoModalAnadirResolucion === false) {
                                setEstadoModalAnadirResolucion(
                                  !estadoModalAnadirResolucion
                                );
                              }
                              if (estadoModalAnadirORPA === true) {
                                setEstadoModalAnadirORPA(
                                  !estadoModalAnadirORPA
                                );
                              }
                            }}
                          >
                            Ver Resoluciones - Constancia de Cumplimiento
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="form-group col-xs-12 col-md-12 col-lg-12">
                        <button
                          type="button"
                          className="btn btn-warning btn-lg btn-block"
                          data-toggle="modal"
                          data-target=".bd-example-modal-lg"
                          onClick={() => {
                            if (estadoModalCancelarSolicitud === true) {
                              setEstadoModalCancelarSolicitud(
                                !estadoModalCancelarSolicitud
                              );
                            }
                            if (estadoModalAnadirResolucion === false) {
                              setEstadoModalAnadirResolucion(
                                !estadoModalAnadirResolucion
                              );
                            }
                            if (estadoModalAnadirORPA === true) {
                              setEstadoModalAnadirORPA(!estadoModalAnadirORPA);
                            }
                          }}
                        >
                          Ver Resoluciones - Constancia de Cumplimiento
                        </button>
                      </div>
                    )
                  ) : null}
                  {checkRequest ? (
                    datos.estado_cumplido === 0 ? (
                      <>
                        <div className="form-group col-xs-12 col-md-4 col-lg-4">
                          <button
                            type="button"
                            className="btn btn-warning btn-lg btn-block"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (estadoModalCancelarSolicitud === true) {
                                setEstadoModalCancelarSolicitud(
                                  !estadoModalCancelarSolicitud
                                );
                              }
                              if (estadoModalAnadirResolucion === false) {
                                setEstadoModalAnadirResolucion(
                                  !estadoModalAnadirResolucion
                                );
                              }
                              if (estadoModalAnadirORPA === true) {
                                setEstadoModalAnadirORPA(
                                  !estadoModalAnadirORPA
                                );
                              }
                            }}
                          >
                            Ver Resoluciones - Constancia de Cumplimiento
                          </button>
                        </div>
                        <div className="form-group col-xs-12 col-md-4 col-lg-4">
                          <button
                            className="btn btn-success btn-lg btn-block"
                            onClick={aceptarSolicitudInterventor}
                            disabled={isDisabled}
                          >
                            {textoBotonAceptar}
                          </button>
                        </div>
                        <div
                          className={"form-group col-xs-12 col-md-4 col-lg-4"}
                        >
                          <button
                            className="btn btn-danger btn-lg btn-block"
                            type="button"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (estadoModalCancelarSolicitud === false) {
                                setEstadoModalCancelarSolicitud(
                                  !estadoModalCancelarSolicitud
                                );
                              }
                            }}
                            disabled={isDisabled}
                          >
                            {textoBotonRechazar}
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="form-group col-xs-12 col-md-12 col-lg-12">
                        <button
                          type="button"
                          className="btn btn-warning btn-lg btn-block"
                          data-toggle="modal"
                          data-target=".bd-example-modal-lg"
                          onClick={() => {
                            if (estadoModalCancelarSolicitud === true) {
                              setEstadoModalCancelarSolicitud(
                                !estadoModalCancelarSolicitud
                              );
                            }
                            if (estadoModalAnadirResolucion === false) {
                              setEstadoModalAnadirResolucion(
                                !estadoModalAnadirResolucion
                              );
                            }
                            if (estadoModalAnadirORPA === true) {
                              setEstadoModalAnadirORPA(!estadoModalAnadirORPA);
                            }
                          }}
                        >
                          Ver Resoluciones - Constancia de Cumplimiento
                        </button>
                      </div>
                    )
                  ) : null}
                  {checkRequestRevisarDocumentos ? (
                    datos.estado_cumplido === 2 ? (
                      <>
                        <div className="form-group col-xs-12 col-md-12 col-lg-4">
                          <button
                            type="button"
                            className="btn btn-warning btn-lg btn-block"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (estadoModalCancelarSolicitud === true) {
                                setEstadoModalCancelarSolicitud(
                                  !estadoModalCancelarSolicitud
                                );
                              }
                              if (estadoModalAnadirResolucion === false) {
                                setEstadoModalAnadirResolucion(
                                  !estadoModalAnadirResolucion
                                );
                              }
                              if (estadoModalAnadirORPA === true) {
                                setEstadoModalAnadirORPA(
                                  !estadoModalAnadirORPA
                                );
                              }
                            }}
                          >
                            Ver Resoluciones - Constancia de Cumplimiento
                          </button>
                        </div>
                        <div className="form-group col-xs-12 col-md-6 col-lg-4">
                          <button
                            className="btn btn-success btn-lg btn-block"
                            onClick={aceptarSolicitudTesoreriaRevisarDocumentos}
                            disabled={isDisabled}
                          >
                            {textoBotonAceptar}
                          </button>
                        </div>
                        <div className="form-group col-xs-12 col-md-6 col-lg-4">
                          <button
                            className="btn btn-danger btn-lg btn-block"
                            type="button"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (estadoModalCancelarSolicitud === false) {
                                setEstadoModalCancelarSolicitud(
                                  !estadoModalCancelarSolicitud
                                );
                              }
                            }}
                            disabled={isDisabled}
                          >
                            {textoBotonRechazar}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-group col-xs-12 col-md-12 col-lg-12">
                          <button
                            type="button"
                            className="btn btn-warning btn-lg btn-block"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (estadoModalCancelarSolicitud === true) {
                                setEstadoModalCancelarSolicitud(
                                  !estadoModalCancelarSolicitud
                                );
                              }
                              if (estadoModalAnadirResolucion === false) {
                                setEstadoModalAnadirResolucion(
                                  !estadoModalAnadirResolucion
                                );
                              }
                              if (estadoModalAnadirORPA === true) {
                                setEstadoModalAnadirORPA(
                                  !estadoModalAnadirORPA
                                );
                              }
                            }}
                          >
                            Ver Resoluciones - Constancia de Cumplimiento
                          </button>
                        </div>
                      </>
                    )
                  ) : null}
                  {checkRequestTesoreria ? (
                    datos.estado_cumplido === 3 ? (
                      <>
                        <div className="form-group col-xs-12 col-md-12 col-lg-4">
                          <button
                            type="button"
                            className="btn btn-warning btn-lg btn-block"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (estadoModalCancelarSolicitud === true) {
                                setEstadoModalCancelarSolicitud(
                                  !estadoModalCancelarSolicitud
                                );
                              }
                              if (estadoModalAnadirResolucion === false) {
                                setEstadoModalAnadirResolucion(
                                  !estadoModalAnadirResolucion
                                );
                              }
                              if (estadoModalAnadirORPA === true) {
                                setEstadoModalAnadirORPA(
                                  !estadoModalAnadirORPA
                                );
                              }
                            }}
                          >
                            Ver Resoluciones - Constancia de Cumplimiento
                          </button>
                        </div>
                        <div className="form-group col-xs-12 col-md-6 col-lg-4">
                          <button
                            className="btn btn-success btn-lg btn-block"
                            onClick={aceptarSolicitudTesoreria}
                            disabled={isDisabled}
                          >
                            {textoBotonAceptar}
                          </button>
                        </div>
                        <div className="form-group col-xs-12 col-md-6 col-lg-4">
                          <button
                            className="btn btn-danger btn-lg btn-block"
                            type="button"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (estadoModalCancelarSolicitud === false) {
                                setEstadoModalCancelarSolicitud(
                                  !estadoModalCancelarSolicitud
                                );
                              }
                            }}
                            disabled={isDisabled}
                          >
                            {textoBotonRechazar}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="form-group col-xs-12 col-md-12 col-lg-12">
                          <button
                            type="button"
                            className="btn btn-warning btn-lg btn-block"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (estadoModalCancelarSolicitud === true) {
                                setEstadoModalCancelarSolicitud(
                                  !estadoModalCancelarSolicitud
                                );
                              }
                              if (estadoModalAnadirResolucion === false) {
                                setEstadoModalAnadirResolucion(
                                  !estadoModalAnadirResolucion
                                );
                              }
                              if (estadoModalAnadirORPA === true) {
                                setEstadoModalAnadirORPA(
                                  !estadoModalAnadirORPA
                                );
                              }
                            }}
                          >
                            Ver Resoluciones - Constancia de Cumplimiento
                          </button>
                        </div>
                      </>
                    )
                  ) : null}

                  {checkRequestTesoreriaOrpa ? (
                    datos.estado_cumplido === 4 ? (
                      <>
                        <div className="form-group col-xs-12 col-md- col-lg-4">
                          <button
                            type="button"
                            className="btn btn-warning btn-lg btn-block"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (estadoModalCancelarSolicitud === true) {
                                setEstadoModalCancelarSolicitud(
                                  !estadoModalCancelarSolicitud
                                );
                              }
                              if (estadoModalAnadirResolucion === false) {
                                setEstadoModalAnadirResolucion(
                                  !estadoModalAnadirResolucion
                                );
                              }
                              if (estadoModalAnadirORPA === true) {
                                setEstadoModalAnadirORPA(
                                  !estadoModalAnadirORPA
                                );
                              }
                            }}
                          >
                            Ver Resoluciones - Constancia de Cumplimiento
                          </button>
                        </div>
                        <div className="form-group col-xs-12 col-md-6 col-lg-4">
                          <button
                            className="btn btn-success btn-lg btn-block"
                            onClick={aceptarSolicitudTesoreriaSegundoPaso}
                            disabled={isDisabled}
                          >
                            {textoBotonAceptar}
                          </button>
                        </div>
                        <div className="form-group col-xs-12 col-md-6 col-lg-4">
                          <button
                            className="btn btn-danger btn-lg btn-block"
                            type="button"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (estadoModalCancelarSolicitud === false) {
                                setEstadoModalCancelarSolicitud(
                                  !estadoModalCancelarSolicitud
                                );
                              }
                            }}
                            disabled={isDisabled}
                          >
                            {textoBotonRechazar}
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className={"form-group col-xs-12 col-md-12 col-lg-12"}
                        >
                          <button
                            type="button"
                            className="btn btn-warning btn-lg btn-block"
                            data-toggle="modal"
                            data-target=".bd-example-modal-lg"
                            onClick={() => {
                              if (estadoModalCancelarSolicitud === true) {
                                setEstadoModalCancelarSolicitud(
                                  !estadoModalCancelarSolicitud
                                );
                              }
                              if (estadoModalAnadirResolucion === false) {
                                setEstadoModalAnadirResolucion(
                                  !estadoModalAnadirResolucion
                                );
                              }
                              if (estadoModalAnadirORPA === true) {
                                setEstadoModalAnadirORPA(
                                  !estadoModalAnadirORPA
                                );
                              }
                            }}
                          >
                            Ver Resoluciones - Constancia de Cumplimiento
                          </button>
                        </div>
                      </>
                    )
                  ) : null}
                  {createRequest && !isDisabled ? (
                    <>
                      {" "}
                      <div
                        className={"form-group col-xs-12 col-md-12 col-lg-12"}
                      >
                        <button
                          onClick={crearSolicitud}
                          className="btn btn-primary btn-lg btn-block"
                        >
                          Crear Solicitud de Pago
                        </button>
                      </div>
                    </>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};

export default Request;
