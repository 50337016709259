import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useNavigate } from "react-router-dom";
import icon_pdf from "../images/tesoreria/pdf.png";

const AddOrpa = (props) => {
  var listaOrpasPorCumplido = [];
  const [count, setCount] = useState(0);
  const [modificarORPA, setModificarORPA] = useState(false);
  const [listaOrpas, setlistaOrpas] = useState([]);
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const array = [
    hoy.getDate().toString(),
    (hoy.getMonth() + 1).toString(),
    hoy.getFullYear().toString(),
  ];
  if (array[1].length === 1) {
    array[1] = "0" + array[1];
  }
  if (array[0].length === 1) {
    array[0] = "0" + array[0];
  }
  const dateToday = array[2] + "-" + array[1] + "-" + array[0];

  const [listaOrpasOriginal, setListaOrpasOriginal] = useState([]);

  const [orpa, setOrpa] = useState({
    id: 1,
    no_orpa: "",
    fecha_orpa: "",
    archivo_orpa: false,
    solicitud_id: props.id !== null ? props.id : "",
  });

  const handleRemove = (id) => {
    const newListaOrpas = listaOrpas.filter((item) => item.id !== id);
    setlistaOrpas(newListaOrpas);
    props.listaFinalOrpas(newListaOrpas);
  };

  const anadirLista = () => {
    var flag = true;
    if (
      orpa.no_orpa !== "" &&
      orpa.fecha_orpa !== "" &&
      orpa.archivo_orpa !== false
    ) {
      if (listaOrpas.length < 1) {
        listaOrpas.forEach((element) => {
          if (
            element.no_orpa === orpa.no_orpa ||
            element.fecha_orpa === orpa.fecha_orpa
          ) {
            flag = false;
          }
        });
        if (flag === true) {
          setlistaOrpas(listaOrpas.concat(orpa));
          props.listaFinalOrpas(listaOrpas.concat(orpa));
          setCount(count + 1);
          setOrpa({
            ...orpa,
            fecha_orpa: "",
            no_orpa: "",
            archivo_orpa: false,
          });
        }
      } else {
        toast.error("Solo se puede añadir una orden de pago por cumplido.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      toast.error("Hay campos pendientes por llenar.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    if (props.arregloFinalOrpas !== undefined) {
      setlistaOrpas(props.arregloFinalOrpas);
    }
    if (props.id !== null) {
      orpaPorCumplido(props.id);
      if (props.state === "solicitudes_revisadas") {
        setModificarORPA(true);
      }
    }
  }, []);

  const orpaPorCumplido = async (id) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL}/orpa-por-solicitud/?solicitud_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        listaOrpasPorCumplido = response.data;
      });
    var arreglo_orpas = [];
    for (let index = 0; index < listaOrpasPorCumplido.length; index++) {
      const element = listaOrpasPorCumplido[index];
      let elemento = {
        id: index + 1,
        id_orpa: element.id_orpa,
        fecha_orpa: element.fecha_orpa,
        no_orpa: element.no_orpa,
        enlace_orpa: element.enlace_orpa,
        empresa_orpa: element.empresa_orpa,
      };
      arreglo_orpas.push(elemento);
    }
    setListaOrpasOriginal(arreglo_orpas);
    setlistaOrpas(arreglo_orpas);
  };

  return (
    <Fragment>
      <div className="row">
        <div
          className="form-group col-xs-12 col-md-12 col-lg-12"
          style={{ marginBottom: "3%", marginTop: "1%" }}
        >
          <h4>
            {props.estadoCumplido === 3
              ? "Registrar Orden de Pago"
              : props.estadoCumplido === 4 || props.estadoCumplido === 5
              ? "Ver Orden de Pago"
              : ""}
          </h4>
        </div>
      </div>
      {props.estadoCumplido === 3 ? (
        <>
          <div>
            <div className="row">
              <div className="form-group col-xs-12 col-md-6 col-lg-3 col-xl-3">
                <label>
                  Número de Orden de Pago <font color="red">*</font>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={orpa.no_orpa}
                  onChange={(e) => {
                    setOrpa({
                      ...orpa,
                      no_orpa: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="form-group col-xs-12 col-md-6 col-lg-3 col-xl-3">
                <label>
                  Fecha de Orden de Pago <font color="red">*</font>
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={orpa.fecha_orpa}
                  max={dateToday}
                  onChange={(e) => {
                    setOrpa({
                      ...orpa,
                      fecha_orpa: e.target.value,
                    });
                  }}
                  placeholder="Número de Resolución"
                />
              </div>
              <div className="form-group col-xs-12 col-md-6 col-xl-3 col-lg-3">
                <label>
                  Adjuntar Orden de Pago <font color="red">*</font>
                </label>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="inputGroupFile"
                    accept=".pdf"
                    onChange={(e) => {
                      if (e.target.files[0].size < 4429811) {
                        setOrpa({
                          ...orpa,
                          archivo_orpa: e.target.files[0],
                        });
                      } else {
                        toast.error("El archivo debe pesar menos de 4MB.", {
                          position: "top-center",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    }}
                  />
                  <label className="custom-file-label">
                    {orpa.archivo_orpa === false
                      ? "Seleccionar Archivo"
                      : orpa.archivo_orpa.name.length > 20
                      ? orpa.archivo_orpa.name.substring(0, 20) + "...pdf"
                      : orpa.archivo_orpa.name}
                  </label>{" "}
                </div>
              </div>
              <div className="form-group col-xs-12 col-md-6 col-xl-3 col-lg-3">
                <label> </label>
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-success btn-block"
                    onClick={anadirLista}
                  >
                    Añadir
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <table className="table text-center">
        <thead className="thead-dark text-center">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Número de Orden de Pago</th>
            <th scope="col">Fecha de Orden de Pago</th>
            {props.estadoCumplido === 5 ||
            props.estadoCumplido === 4 ||
            (props.estadoCumplido === 3 && listaOrpas.length > 0) ? (
              <th scope="col">Orden de Pago</th>
            ) : null}
            <th scope="col">
              {props.estadoCumplido === 3 ? "Opcion" : "Empresa"}
            </th>
          </tr>
        </thead>
        <tbody>
          {listaOrpas.map((item) => (
            <tr key={item.id}>
              <th>{item.id}</th>
              <td>{item.no_orpa}</td>
              <td>{item.fecha_orpa}</td>

              <td>
                <a
                  title="Resolución"
                  style={
                    props.estadoCumplido === 5 ||
                    props.estadoCumplido === 4 ||
                    props.estadoCumplido === 3
                      ? { display: "block" }
                      : { display: "none" }
                  }
                  target="_blank"
                  href={item.enlace_orpa}
                >
                  <img src={icon_pdf} alt="Cumplido" />
                </a>
              </td>
              {props.estadoCumplido === 3 ? (
                <td>
                  <div className="row justify-content-md-center">
                    <button
                      style={
                        props.estadoCumplido === 3
                          ? { display: "block" }
                          : { display: "none" }
                      }
                      onClick={() => handleRemove(item.id)}
                      type="button"
                      className="btn btn-danger"
                    >
                      Eliminar
                    </button>
                  </div>
                </td>
              ) : (
                <td>{item.empresa_orpa}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {modificarORPA && listaOrpas.length > 0 ? (
          <>
            <button
              type="button"
              className="btn btn-secondary btn-lg btn-block"
              data-dismiss="modal"
              aria-label="Close"
            >
              Siguiente
            </button>
          </>
        ) : (
          <></>
        )}
    </Fragment>
  );
};

export default AddOrpa;
