import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useNavigate } from "react-router-dom";
import icon_pdf from "../images/tesoreria/pdf.png";

const AddResolucion = (props) => {
  var listaResolucionesPorSolicitud = [];
  const [titulo, setTitulo] = useState("");
  const [verResoluciones, setVerResoluciones] = useState(false);
  const [count, setCount] = useState(0);
  const [modificarSolicitud, setModificarSolicitud] = useState(false);
  const [listaResoluciones, setlistaResoluciones] = useState([]);
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const array = [
    hoy.getDate().toString(),
    (hoy.getMonth() + 1).toString(),
    hoy.getFullYear().toString(),
  ];
  if (array[1].length === 1) {
    array[1] = "0" + array[1];
  }
  if (array[0].length === 1) {
    array[0] = "0" + array[0];
  }
  const dateToday = array[2] + "-" + array[1] + "-" + array[0];

  const [listaResolucionesOriginal, setlistaResolucionesOriginal] = useState(
    []
  );

  const [verOrdenadorGasto, setVerOrdenadorGasto] = useState([]);
  const [listaResoluciones_Aux, setlistaResoluciones_Aux] = useState([]);
  const [relacionResolucion, setRelacionResolucion] = useState({
    id: 1,
    fechaResolucion: "",
    numeroResolucion: "",
    ordenadorGastoResolucion: "",
    documentoResolucion: false,
  });
  const handleRemove = (id) => {
    const newlistaResoluciones = listaResoluciones.filter(
      (item) => item.id !== id
    );
    setlistaResoluciones(newlistaResoluciones);
    props.listaFinalResoluciones(newlistaResoluciones);
  };

  
  const anadirLista = () => {
    var flag = true;
    if (
      relacionResolucion.fechaResolucion !== "" &&
      relacionResolucion.numeroResolucion !== "" &&
      relacionResolucion.documentoResolucion !== false
    ) {
      listaResoluciones.forEach((element) => {
        if (
          element.fechaResolucion === relacionResolucion.fechaResolucion ||
          element.numeroResolucion === relacionResolucion.numeroResolucion
        ) {
          flag = false;
        }
      });
      if (flag === true) {
        setlistaResoluciones(listaResoluciones.concat(relacionResolucion));
        props.listaFinalResoluciones(
          listaResoluciones.concat(relacionResolucion)
        );
        setCount(count + 1);
        setRelacionResolucion({
          ...relacionResolucion,
          fechaResolucion: "",
          numeroResolucion: "",
          documentoResolucion: false,
        });
      }
    } else {
      toast.error("Hay campos pendientes por llenar.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    if (
      props.state === "solicitud_para_interventor" ||
      props.state === "solicitud_usuario" ||
      props.state === "solicitud_para_revisar" ||
      props.state === "solicitudes_revisadas" ||
      props.state === "solicitud_con_orpa"
    ) {
      setTitulo("Ver Resoluciones");
      setVerResoluciones(true);
    } else {
      setTitulo("Registrar Resoluciones");
      setVerResoluciones(false);
    }
    if (props.arregloFinalResoluciones !== undefined) {
      setlistaResoluciones(props.arregloFinalResoluciones);
    }
    if (props.id !== null) {
      resolucionesPorSolicitud(props.id);
    } else {
      setModificarSolicitud(true);
    }
  }, []);

  const resolucionesPorSolicitud = async (id) => {
    var arreglo_resoluciones = [];
    await axios
      .get(
        `${process.env.REACT_APP_URL}/resolucion-por-solicitud/?solicitud_id=${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        listaResolucionesPorSolicitud = response.data;
      });
    for (let index = 0; index < listaResolucionesPorSolicitud.length; index++) {
      const element = listaResolucionesPorSolicitud[index];
      let elemento = {
        id: index + 1,
        idTabla: element.resolucion_id,
        fechaResolucion: element.fecha_resolucion,
        numeroResolucion: element.num_resolucion,
        ordenadorGastoResolucion: element.ordenador_gasto,
        enlaceResolucion: element.enlace_resolucion,
      };
      arreglo_resoluciones.push(elemento);
    }
    setlistaResolucionesOriginal(arreglo_resoluciones);
    setlistaResoluciones(arreglo_resoluciones);
  };

  return (
    <Fragment>
      <div className="">
        <div className="modal-header">
          <h4 className="modal-title">{titulo}</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {!verResoluciones ? (
          <>
            <div
              className="form-group col-xs-12 col-md-12 col-lg-12"
              style={{ marginBottom: "3%", marginTop: "1%" }}
            >
              <a>
                <strong>Notas: </strong>
              </a>
              <br></br>
              <a>
                <strong>a) </strong>La resolución que se adjunte debe
                etiquetarse según la siguiente información: A. Empresa QUIPU. B.
                Tipo de resolución. C. Número de resolución. D. Año resolución.
                Ejemplo: 2010- REA-130-2022.
              </a>
              <br></br>
              <a>
                <strong>b) </strong>El documento adjunto debe estar en formato
                PDF.
              </a>
            </div>
            <div className="row">
              <div className="form-group col-xs-12 col-md-6 col-lg-6 col-xl-6">
                <label>
                  Número de Resolución <font color="red">*</font>
                </label>
                <input
                  type="text"
                  pattern={"[0-9]+"}
                  maxLength="10"
                  className="form-control"
                  value={relacionResolucion.numeroResolucion}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      setRelacionResolucion({
                        ...relacionResolucion,
                        numeroResolucion: e.target.value,
                        id: count + 1,
                      });
                    }
                  }}
                />
              </div>

              <div className="form-group col-xs-12 col-md-6 col-lg-6 col-xl-6">
                <label>
                  Fecha de expedición de la resolución{" "}
                  <font color="red">*</font>
                </label>
                <input
                  type="date"
                  className="form-control"
                  value={relacionResolucion.fechaResolucion}
                  max={dateToday}
                  onChange={(e) => {
                    setRelacionResolucion({
                      ...relacionResolucion,
                      fechaResolucion: e.target.value,
                      id: count + 1,
                    });
                  }}
                  placeholder="Número de Resolución"
                />
              </div>
              <div className="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6">
                <label>
                  Adjuntar Resolución <font color="red">*</font>
                </label>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="inputGroupFile"
                    accept=".pdf"
                    onChange={(e) => {
                      if (e.target.files[0].size < 4429811) {
                        setRelacionResolucion({
                          ...relacionResolucion,
                          documentoResolucion: e.target.files[0],
                          id: count + 1,
                        });
                      } else {
                        toast.error("El archivo debe pesar menos de 4MB.", {
                          position: "top-center",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                      }
                    }}
                  />
                  <label className="custom-file-label">
                    {relacionResolucion.documentoResolucion === false
                      ? "Seleccionar Archivo"
                      : relacionResolucion.documentoResolucion.name.length > 20
                      ? relacionResolucion.documentoResolucion.name.substring(
                          0,
                          20
                        ) + "...pdf"
                      : relacionResolucion.documentoResolucion.name}
                  </label>
                </div>
              </div>

              <div className="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6">
                <label> </label>
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-success btn-block"
                    onClick={anadirLista}
                  >
                    Añadir
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <table className="table text-center">
          <thead className="thead-dark text-center">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Fecha</th>
              <th scope="col">No Resolución</th>
              <th scope="col">
                {props.state !== "solicitud_usuario" ? "Opcion" : "Resolucion"}{" "}
              </th>
            </tr>
          </thead>
          <tbody>
            {listaResoluciones.map((item) => (
              <tr key={item.id}>
                <th>{item.id}</th>
                <td>{item.fechaResolucion}</td>
                <td>{item.numeroResolucion}</td>
                <td>
                  <div className="row justify-content-md-center">
                    <button
                      style={
                        modificarSolicitud === true
                          ? { display: "block" }
                          : { display: "none" }
                      }
                      onClick={() => handleRemove(item.id)}
                      type="button"
                      className="btn btn-danger"
                    >
                      Eliminar
                    </button>
                    <a
                      title="Resolución"
                      href={item.enlaceResolucion}
                      style={
                        verResoluciones
                          ? { display: "block" }
                          : { display: "none" }
                      }
                      target="_blank"
                    >
                      <img src={icon_pdf} alt="Resolución" />
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {!verResoluciones && listaResoluciones.length > 0 ? (
          <>
            <button
              type="button"
              className="btn btn-secondary btn-lg btn-block"
              data-dismiss="modal"
              aria-label="Close"
            >
              Siguiente
            </button>
          </>
        ) : (
          <></>
        )}
      </div>
    </Fragment>
  );
};

export default AddResolucion;
