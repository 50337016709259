import crear_solicitud from "../images/logistica/form.png";
import ver_solicitud from "../images/logistica/carpeta.png";
import gestionar_solicitud from "../images/logistica/request.png";
import solicitudes_tesoreria from "../images/tesoreria/folder.png";
import solicitudes_tesoreria_orpa from "../images/tesoreria/carpeta_revisar.png";
import tramitar_solicitud from "../images/tesoreria/tramitar_solicitud_2.png";
import buscar_solicitud from "../images/tesoreria/consulta_documentos.png";
import ver_reportes from "../images/tesoreria/reportes.png";
//import ver_estadisticas from "../images/logistica/investigacion.png";
import herramientas from "../images/tesoreria/roles.png";
import Header from "./Header";
import React, { Fragment, useEffect, useState } from "react";
import Footer from "./Footer";
import { useLocation, Link, NavLink } from "react-router-dom";
import axios from "axios";
import fondo from "../images/transporte/fondo-menu.png";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth/store";

const Menu = (props) => {
  const auth = useAuth();
  const [rolInterventor, setRolInterventor] = useState();

  useEffect(() => {
    verGestor(auth.user.username);
  }, []);

  const verGestor = async (usuario) => {
    await axios
      .get(
        `${process.env.REACT_APP_URL}/numero-solicitudes-para-responsable/?correo_interventor=${usuario}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response2) => {
        setRolInterventor(response2.data);
      });
  };

  return (
    <Fragment>
      <Header />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />{" "}
      <main className="detalle">
        <div
          className="breadcrumb-class"
          style={{
            display: "block",
            marginLeft: "auto !important",
            marginRight: "auto",
            marginTop: "3%",
            marginBottom: "5%",
            fontSize: "16px !important",
          }}
        >
          <a>Está en:</a>
          <NavLink to="/">Inicio </NavLink>/{" "}
          <span> {"Menú Radicación solicitudes de pago"}</span>
        </div>

        <div className="row">
          <div className="col align-self-center">
            <div
              className="row justify-content-md-center menu"
              style={{ marginTop: "5%" }}
            >
              {auth.user.rol === 0 ? (
                rolInterventor > 0 ? (
                  <>
                    <div
                      className={
                        "col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4"
                      }
                    >
                      <NavLink to={"/crear-solicitud"}>
                        <figure id="a" className="figure-img text-center">
                          <img
                            src={crear_solicitud}
                            className="figure-img img-fluid rounded"
                            alt="Crear Solicitud de Pago"
                          />
                          <h5 style={{ color: "black" }}>
                            Crear Solicitud de Pago
                          </h5>
                        </figure>
                      </NavLink>
                    </div>
                    <div
                      className={
                        "col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4"
                      }
                    >
                      <NavLink to={"/mis-solicitudes"}>
                        <figure id="a" className="figure-img text-center">
                          <img
                            src={ver_solicitud}
                            className="figure-img img-fluid rounded"
                            alt="Mis Solicitudes"
                          />
                          <h5 style={{ color: "black" }}>Mis Solicitudes</h5>
                        </figure>
                      </NavLink>
                    </div>
                    <div
                      className={
                        "col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4"
                      }
                    >
                      <NavLink to={"/gestionar-solicitudes"}>
                        <figure id="a" className="figure-img text-center">
                          <img
                            src={gestionar_solicitud}
                            className="figure-img img-fluid rounded"
                            alt="Gestionar Solicitud"
                          />
                          <h5 style={{ color: "black" }}>
                            Aprobar Solicitudes de Pago
                          </h5>
                        </figure>
                      </NavLink>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <NavLink to={"/crear-solicitud"}>
                        <figure id="a" className="figure-img text-center">
                          <img
                            src={crear_solicitud}
                            className="figure-img img-fluid rounded"
                            alt="Crear Solicitud de Pago"
                          />
                          <h5 style={{ color: "black" }}>
                            Crear Solicitud de Pago
                          </h5>
                        </figure>
                      </NavLink>
                    </div>
                    <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                      <NavLink to={"/mis-solicitudes"}>
                        <figure id="a" className="figure-img text-center">
                          <img
                            src={ver_solicitud}
                            className="figure-img img-fluid rounded"
                            alt="Mis Solicitudes"
                          />
                          <h5 style={{ color: "black" }}>Mis Solicitudes</h5>
                        </figure>
                      </NavLink>
                    </div>
                  </>
                )
              ) : (
                <></>
              )}
              {auth.user.rol === 1 ? (
                <>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <NavLink to={"/crear-solicitud"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={crear_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Crear Solicitud de Pago"
                        />
                        <h5 style={{ color: "black" }}>
                          Crear Solicitud de Pago
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <NavLink to={"/mis-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={ver_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Mis Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Mis Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <NavLink to={"/gestionar-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={gestionar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Aprobar Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Aprobar Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <NavLink to={"/lista-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={tramitar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Cargar Orpa a Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>
                          Revisar Documentos de Solicitudes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                </>
              ) : (
                <></>
              )}
              {auth.user.rol === 2 ? (
                <>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <NavLink to={"/crear-solicitud"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={crear_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Crear Solicitud de Pago"
                        />
                        <h5 style={{ color: "black" }}>
                          Crear Solicitud de Pago
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <NavLink to={"/mis-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={ver_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Mis Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Mis Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <NavLink to={"/gestionar-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={gestionar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Aprobar Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Aprobar Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                    <NavLink to={"/lista-solicitudes-revisadas"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={solicitudes_tesoreria}
                          className="figure-img img-fluid rounded"
                          alt="Cargar Orpa a Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>
                          Cargar Orpa a Solicitudes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                </>
              ) : (
                <></>
              )}
              {auth.user.rol === 3 ? (
                <>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                    <NavLink to={"/crear-solicitud"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={crear_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Crear Solicitud de Pago"
                        />
                        <h5 style={{ color: "black" }}>
                          Crear Solicitud de Pago
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                    <NavLink to={"/mis-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={ver_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Mis Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Mis Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                    <NavLink to={"/gestionar-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={gestionar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Aprobar Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Aprobar Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                    <NavLink to={"/cumplidos-con-orpa"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={solicitudes_tesoreria_orpa}
                          className="figure-img img-fluid rounded"
                          alt="Gestionar Orpa Liquidada"
                        />
                        <h5 style={{ color: "black" }}>
                          Gestionar Orpa Liquidada
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                    <NavLink to={"/consultar-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={buscar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Revisar  Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>
                          Consultar Solicitudes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>

                </>
              ) : (
                <></>
              )}
              {auth.user.rol === 4 ? (
                <>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                    <NavLink to={"/crear-solicitud"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={crear_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Crear Solicitud de Pago"
                        />
                        <h5 style={{ color: "black" }}>
                          Crear Solicitud de Pago
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                    <NavLink to={"/mis-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={ver_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Mis Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Mis Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                    <NavLink to={"/gestionar-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={gestionar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Aprobar Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Aprobar Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                    <NavLink to={"/lista-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={tramitar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Cargar Orpa a Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>
                          Revisar Documentos de Solicitudes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                    <NavLink to={"/lista-solicitudes-revisadas"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={solicitudes_tesoreria}
                          className="figure-img img-fluid rounded"
                          alt="Cargar Orpa a Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>
                          Cargar Orpa a Solicitudes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                    <NavLink to={"/consultar-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={buscar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Revisar  Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>
                          Consultar Solicitudes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                </>
              ) : (
                <></>
              )}

              {auth.user.rol === 5 ? (
                <>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <NavLink to={"/crear-solicitud"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={crear_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Crear Solicitud de Pago"
                        />
                        <h5 style={{ color: "black" }}>
                          Crear Solicitud de Pago
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ">
                    <NavLink to={"/mis-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={ver_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Mis Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Mis Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <NavLink to={"/gestionar-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={gestionar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Aprobar Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Aprobar Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <NavLink to={"/lista-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={tramitar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Revisar Documentos de Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>
                          Revisar Documentos de Solicitudes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>

                  <div className="col col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <NavLink to={"/lista-solicitudes-revisadas"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={solicitudes_tesoreria}
                          className="figure-img img-fluid rounded"
                          alt="Cargar Orpa a Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>
                          Cargar Orpa a Solicitudes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <NavLink to={"/cumplidos-con-orpa"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={solicitudes_tesoreria_orpa}
                          className="figure-img img-fluid rounded"
                          alt="Gestionar Orpa Liquidada"
                        />
                        <h5 style={{ color: "black" }}>
                          Gestionar Orpa Liquidada
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                </>
              ) : (
                <></>
              )}

              {auth.user.rol === 6 ? (
                <>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <NavLink to={"/crear-solicitud"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={crear_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Crear Solicitud de Pago"
                        />
                        <h5 style={{ color: "black" }}>
                          Crear Solicitud de Pago
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 ">
                    <NavLink to={"/mis-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={ver_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Mis Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Mis Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <NavLink to={"/gestionar-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={gestionar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Aprobar Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Aprobar Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <NavLink to={"/consultar-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={buscar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Revisar  Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>
                          Consultar Solicitudes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <NavLink to={"/lista-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={tramitar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Revisar Documentos de Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>
                          Revisar Documentos de Solicitudes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <NavLink to={"/lista-solicitudes-revisadas"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={solicitudes_tesoreria}
                          className="figure-img img-fluid rounded"
                          alt="Cargar Orpa a Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>
                          Cargar Orpa a Solicitudes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <NavLink to={"/cumplidos-con-orpa"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={solicitudes_tesoreria_orpa}
                          className="figure-img img-fluid rounded"
                          alt="Gestionar Orpa Liquidada"
                        />
                        <h5 style={{ color: "black" }}>
                          Gestionar Orpa Liquidada
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <NavLink to={"/reportes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={ver_reportes}
                          className="figure-img img-fluid rounded"
                          alt="Generar Reportes"
                        />
                        <h5 style={{ color: "black" }}>
                          Generación de Reportes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <NavLink to={"/menu-administrador"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={herramientas}
                          className="figure-img img-fluid rounded"
                          alt="Administrar"
                        />
                        <h5 style={{ color: "black" }}>
                          Administración de Usuarios
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                </>
              ) : (
                <></>
              )}
              {auth.user.rol === 7 ? (
                <>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                    <NavLink to={"/crear-solicitud"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={crear_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Crear Solicitud de Pago"
                        />
                        <h5 style={{ color: "black" }}>
                          Crear Solicitud de Pago
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3 ">
                    <NavLink to={"/mis-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={ver_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Mis Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Mis Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                    <NavLink to={"/gestionar-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={gestionar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Aprobar Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>Aprobar Solicitudes</h5>
                      </figure>
                    </NavLink>
                  </div>
                  <div className="col col-12 col-sm-6 col-md-6 col-lg-6 col-xl-3">
                    <NavLink to={"/consultar-solicitudes"}>
                      <figure id="a" className="figure-img text-center">
                        <img
                          src={buscar_solicitud}
                          className="figure-img img-fluid rounded"
                          alt="Revisar  Solicitudes"
                        />
                        <h5 style={{ color: "black" }}>
                          Consultar Solicitudes
                        </h5>
                      </figure>
                    </NavLink>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};

export default Menu;
