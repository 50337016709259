import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

export const DialogLeavingPage = ({
  showDialog,
  setShowDialog,
  cancelNavigation,
  confirmNavigation,
}) => {
  const handleDialogClose = () => {
    setShowDialog(false);
  };

  return (
    <Dialog fullWidth open={showDialog} onClose={handleDialogClose}>
      <div className="container">
        <br></br>
        <h3 >Advertencia</h3>
        <DialogContent>
          <h5>¿Desea abandonar la gestión de esta solicitud?</h5>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={cancelNavigation}>
            No
          </Button>
          <Button variant="contained" onClick={confirmNavigation}>
            Sí
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
