import React, { useEffect, useState, Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link, NavLink } from "react-router-dom";
import axios from "axios";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import cellEditFactory from "react-bootstrap-table2-editor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth/store";

const ListUsers = (props) => {
  const history = useNavigate();
  const [estadoEliminarCambiar, setEstadoEliminarCambiar] = useState(false);
  const auth = useAuth();
  const [programmers, setProgrammers] = useState([]);
  const [variableMantenimiento, setVariableMantenimiento] = useState();
  const [rol, setRol] = useState();
  const [usuario, setUsuario] = useState({
    nombre: auth.user.first_name + " " + auth.user.last_name,
    correo: auth.user.email,
    usuario: auth.user.username,
    rol: auth.user.rol,
  });

  const [programmer, setProgrammer] = useState({
    correo_usuario_t: "",
    id_usuario_t: "",
    nombre_usuario_t: "",
    rol_usuario_t: "",
  });

  const columnsProgrammer = [
    {
      dataField: "nombre_usuario_t",
      text: "Nombre de Usuario",
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "correo_usuario_t",
      text: "Correo de Usuario",
      filter: textFilter(),
      editable: false,
    },
    {
      dataField: "rol_usuario_t",
      filter: textFilter(),
      text: "Rol de Usuario (Numérico)",
    },
    {
      dataField: "id_usuario_t",
      formatter: optionFormatter,
      text: "Opciones",
    },
  ];

  const buscarSolicitante = async (cor) => {
    await axios
      .post(
        `${process.env.REACT_APP_URL}/consulta-ldap/`,
        {
          correo: cor,
          cc: "",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        setProgrammer({
          ...programmer,
          correo_usuario_t: response.data.mail[0],
          nombre_usuario_t: response.data.cn[0],
        });
      })
      .catch((error) => {
        toast.error("No se encuentra el usuario.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setProgrammer({
          ...programmer,
          correo_usuario_t: "",
          nombre_usuario_t: "",
        });
      });
  };

  const eliminarGestor = async (id) => {
    let value = programmers.find((element) => element.id_usuario_t == id);
    await axios
      .delete(`${process.env.REACT_APP_URL}/roles/${value.id_usuario_t}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response2) => {
        if (response2.status == 200) {
          toast.success("Gestor eliminado correctamente.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setEstadoEliminarCambiar(true);
        }
      });
  };

  const registrarGestor = async (e) => {
    e.preventDefault();
    try {
      await axios
        .post(`${process.env.REACT_APP_URL}/roles/`, programmer, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response2) => {
          if (response2.status == 201) {
            toast.success("Gestor Creado Correctamente.", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setEstadoEliminarCambiar(true);
          }
        })
        .catch((error) => {
          toast.error("Ocurrió un error al realizar el registro.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } catch (error) {
      toast.warn("Hay campos pendientes por llenar.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const editarGestor = async (id) => {
    let value = programmers.find((element) => element.id_usuario_t == id);
    value.rol_usuario_t = parseInt(value.rol_usuario_t, 10);
    try {
      const res_editar_gestor = await axios.put(
        `${process.env.REACT_APP_URL}/roles/${value.id_usuario_t}`,
        value,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res_editar_gestor.status === 200) {
        toast.success("Cambios guardados correctamente.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Ocurrió un error en la solicitud.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  function optionFormatter(e) {
    return (
      <div>
        <div className="row justify-content-md-center">
          <button
            onClick={() => {
              editarGestor(e);
            }}
            style={{ marginRight: "5%", marginBottom: "3%" }}
            type="button"
            className="btn btn-success"
          >
            Guardar
          </button>
          <button
            onClick={() => {
              eliminarGestor(e);
            }}
            style={{ marginBottom: "3%" }}
            type="button"
            className="btn btn-danger"
          >
            Eliminar
          </button>
        </div>
      </div>
    );
  }
  useEffect(() => {
    validarEstadoMantenimiento();
    setEstadoEliminarCambiar(false);
    verUsuarios();
  }, [estadoEliminarCambiar]);

  const validarEstadoMantenimiento = () => {
    auth.ismaintain((estado_pagina) => {
      if (estado_pagina === 1) {
        setVariableMantenimiento("habilitar");
      } else if (estado_pagina === 0) {
        setVariableMantenimiento("deshabilitar");
      }
    });
  };

  const cambiarEstadoMantenimiento = async (e) => {
    e.preventDefault();
    try {
      console.log(variableMantenimiento);
      let cambio_estado_pagina;
      if (variableMantenimiento === "habilitar") {
        cambio_estado_pagina = 0;
      } else {
        cambio_estado_pagina = 1;
      }
      await axios
        .put(
          `${process.env.REACT_APP_URL}/mantenimiento/1`,
          {
            estado_pagina: cambio_estado_pagina,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.estado_pagina === 0) {
            setVariableMantenimiento("deshabilitar");
          } else {
            setVariableMantenimiento("habilitar");
          }
        });
    } catch (error) {
      //console.log(error);
    }
  };

  const verUsuarios = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_URL}/roles/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setProgrammers(response.data);
        });
    } catch (error) {
      //console.log(error);
    } finally {
    }
  };

  return (
    <Fragment>
      <Header usuario={usuario} />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />{" "}
      <main className="detalle">
        <div
          className="breadcrumb-class"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            marginTop: "3%",
            marginBottom: "5%",
            fontSize: "16px",
          }}
        >
          Está en:
          <a
            href="https://serviciosdfabog.unal.edu.co"
            target="_self"
            title="Inicio"
          >
            Inicio
          </a>
          / <NavLink to="/menu">Menu Radicación de Cumplidos</NavLink> /{" "}
          <span>{"Administración de Usuarios"}</span>
        </div>
        <div className="container" style={{ marginTop: "5%" }}>
          <div style={rol === 3 ? { display: "none" } : { display: "block" }}>
            <form className="needs-validation" noValidate>
              <div className="form-row">
                <div className="col-md-2 mb-3">
                  <label>Correo Electrónico</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustomUsername"
                      aria-describedby="inputGroupPrepend"
                      onChange={(e) => {
                        setProgrammer({
                          ...programmer,
                          correo_usuario_t: e.target.value,
                        });
                      }}
                      onBlur={(e) => {
                        if (e.target.value !== "") {
                          buscarSolicitante(e.target.value);
                        } else {
                          setProgrammer({
                            ...programmer,
                            correo_usuario_t: "",
                            nombre_usuario_t: "",
                          });
                        }
                      }}
                      value={programmer.correo_usuario_t}
                      required
                    />
                  </div>
                </div>

                <div className={"col-md-2 mb-3"}>
                  <label>{"Nombres y Apellidos"}</label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom02"
                    value={programmer.nombre_usuario_t}
                    disabled
                  />
                  <div className="valid-feedback">Correcto!</div>
                </div>
                <div className={"col-md-2 mb-3"}>
                  <label> {"Rol de Usuario"}</label>

                  <div>
                    <select
                      className="custom-select"
                      id="inputGroupSelect01"
                      onChange={(e) => {
                        setProgrammer({
                          ...programmer,
                          rol_usuario_t: e.target.value,
                        });
                      }}
                    >
                      <option selected>Seleccione</option>
                      <option value="1">Revisor (Rol 1)</option>
                      <option value="2">Liquidador (Rol 2)</option>
                      <option value="3">Aprobador (Rol 3)</option>
                      <option value="4">Revisor y Liquidador (Rol 4)</option>
                      <option value="5">
                        Aprobador, Revisor y Liquidador (Rol 5)
                      </option>
                      <option value="6">Administrador (Rol 6)</option>
                      <option value="7">Consultor (Rol 7)</option>
                    </select>
                  </div>
                  <div className="valid-feedback">Correcto!</div>
                </div>
                <div className="col-md- mb-3">
                  <br></br>
                  <button className="btn btn-primary" onClick={registrarGestor}>
                    {"Registrar Usuario"}
                  </button>
                </div>

                <div className="col-md-2 mb-3">
                  <br></br>
                  <label>
                    ¿Desea {variableMantenimiento} la página para realizar
                    solicitudes?
                  </label>
                </div>
                <div className="col-md-2 mb-3">
                  <br></br>

                  <button
                    className={
                      variableMantenimiento === "deshabilitar"
                        ? "btn btn-danger"
                        : "btn btn-success"
                    }
                    onClick={cambiarEstadoMantenimiento}
                  >
                    Sí, deseo {variableMantenimiento}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <BootstrapTable
            wrapperClasses="table-responsive"
            classes="table text-center"
            headerWrapperClasses="thead-dark"
            bordered
            bootstrap4
            keyField={"id_usuario_t"}
            data={programmers}
            columns={columnsProgrammer}
            pagination={paginationFactory()}
            filter={filterFactory()}
            cellEdit={cellEditFactory({
              mode: "click",
              blurToSave: true,
            })}
          />
        </div>
      </main>
      <Footer />
    </Fragment>
  );
};

export default ListUsers;
