import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Popover, OverlayTrigger } from "react-bootstrap";
import aprobado from "../images/tesoreria/aprobado_responsable.png";
import rechazado from "../images/tesoreria/rechazado_responsable.png";
import esperar from "../images/tesoreria/letra.png";
import cambio_tesoreria from "../images/tesoreria/cambiar.png";
import flecha from "../images/tesoreria/flecha.png";
import en_revision from "../images/tesoreria/lupa.png";
import esperar_tesoreria from "../images/tesoreria/reloj-de-arena.png";
import aprobado_tesoreria from "../images/tesoreria/aprobado_tesoreria2.png";
import rechazado_tesoreria from "../images/tesoreria/rechazado_tesoreria2.png";
import revision_documentos from "../images/tesoreria/search.png";
import cancelado from "../images/tesoreria/cancelar.png";
import { useLocation } from "react-router-dom";

const WatchHistoryRequest = (props) => {
  var listaResolucionesPorSolicitud = [];
  const location = useLocation();
  const [titulo, setTitulo] = useState("");
  const [listaResoluciones, setlistaResoluciones] = useState([]);
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const array = [
    hoy.getDate().toString(),
    (hoy.getMonth() + 1).toString(),
    hoy.getFullYear().toString(),
  ];
  if (array[1].length === 1) {
    array[1] = "0" + array[1];
  }
  if (array[0].length === 1) {
    array[0] = "0" + array[0];
  }

  function celdaFechaSolicitud(cell) {
    return cell.split("T")[0] + "  " + cell.split("T")[1].split(".")[0];
  }

  function celdaEstadoSolicitud(cell) {
    switch (cell) {
      case 0:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">Solicitud Creada</Popover.Header>
              </Popover>
            }
          >
            <img alt="esperar" src={esperar} />
          </OverlayTrigger>
        );
      case 1:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Rechazado por Responsable de Seguimiento
                </Popover.Header>
              </Popover>
            }
          >
            <img alt="rechazado" src={rechazado} />
          </OverlayTrigger>
        );
      case 2:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Aprobado por Responsable del Seguimiento || Pendiente de
                  Revisar Documentos - Tesorería
                </Popover.Header>
              </Popover>
            }
          >
            <div>
              <img alt="aprobado" src={aprobado} />
              <img alt="flecha" src={flecha} />
              <img alt="esperar" src={esperar_tesoreria} />
            </div>
          </OverlayTrigger>
        );

      case 3:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Aprobado por Responsable del Seguimiento || Pendiente de
                  Cargar Orden de Pago - Tesoreria
                </Popover.Header>
              </Popover>
            }
          >
            <div>
              <img alt="aprobado" src={aprobado} />
              <img alt="flecha" src={flecha} />
              <img alt="esperar" src={revision_documentos} />
            </div>
          </OverlayTrigger>
        );

      case 4:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Aprobado por Responsable del Seguimiento || Pendiente de
                  Revisar Orden de Pago - Tesoreria
                </Popover.Header>
              </Popover>
            }
          >
            <div>
              <img alt="aprobado" src={aprobado} />
              <img alt="flecha" src={flecha} />
              <img alt="en revision" src={en_revision} />
            </div>
          </OverlayTrigger>
        );

      case 5:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Aprobado por Responsable del Seguimiento || Aceptado -
                  Tesoreria
                </Popover.Header>
              </Popover>
            }
          >
            <div>
              <img alt="aprobado" src={aprobado} />
              <img alt="flecha" src={flecha} />
              <img alt="aprobado" src={aprobado_tesoreria} />
            </div>
          </OverlayTrigger>
        );

      case 6:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Aprobado por Responsable del Seguimiento || Rechazado -
                  Tesoreria
                </Popover.Header>
              </Popover>
            }
          >
            <div>
              <img alt="aprobado" src={aprobado} />
              <img alt="flecha" src={flecha} />
              <img alt="rechazado" src={rechazado_tesoreria} />
            </div>
          </OverlayTrigger>
        );
      case 7:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">
                  Aprobado por Responsable del Seguimiento || Orden de Pago
                  Devuelta - Tesoreria
                </Popover.Header>
              </Popover>
            }
          >
            <div>
              <img alt="aprobado" src={aprobado} />
              <img alt="flecha" src={flecha} />
              <img alt="cambio" src={cambio_tesoreria} />
            </div>
          </OverlayTrigger>
        );

      case 8:
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Header as="h3">Solicitud Cancelada</Popover.Header>
              </Popover>
            }
          >
            <img alt="cancelado" src={cancelado} />
          </OverlayTrigger>
        );
      default:
        break;
    }
  }

  useEffect(() => {
    setTitulo("Ver Historial de la Solicitud");

    if (props.id !== null) {
      setlistaResoluciones([])
      historialPorSolicitud(props.id);
    }
  }, [props.id]);

  const historialPorSolicitud = async (id) => {
    var arreglo_resoluciones = [];
    var response_solicitud = await axios.get(
      `${process.env.REACT_APP_URL}/solicitud/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    let elemento = {
      id: 1,
      idTabla: response_solicitud.data.solicitud_id,
      fechaCambio: response_solicitud.data.fecha_solicitud,
      rolAutorizador: response_solicitud.data.correo_solicitante.split("@")[0],
      tipoAutorizacion: 0,
      comentarios: "",
    };
    arreglo_resoluciones.push(elemento);
    var historial_solicitud = await axios.get(
      `${process.env.REACT_APP_URL}/historial-por-solicitud/?solicitud=${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    listaResolucionesPorSolicitud = historial_solicitud.data;

    for (let index = 0; index < listaResolucionesPorSolicitud.length; index++) {
      const element = listaResolucionesPorSolicitud[index];
      let elemento = {
        id: index + 2,
        idTabla: element.id_cumplido_tesoreria,
        fechaCambio: element.fecha_modificacion_rol,
        rolAutorizador: element.rol_autorizador,
        tipoAutorizacion: element.tipo_autorizacion,
        comentarios: element.comentario_rol,
      };
      if (location.pathname !== "/mis-solicitudes") {
        arreglo_resoluciones.push(elemento);
      } else {
        if (
          elemento.tipoAutorizacion === 0 ||
          elemento.tipoAutorizacion === 1 ||
          elemento.tipoAutorizacion === 2 ||
          elemento.tipoAutorizacion === 5 ||
          elemento.tipoAutorizacion === 6 ||
          elemento.tipoAutorizacion === 8
        ) {
          if (index > 2) elemento.id = 3;
          arreglo_resoluciones.push(elemento);
        }
      }
    }
    setlistaResoluciones(arreglo_resoluciones);
  };

  return (
    <Fragment>
      <div className="">
        <div className="modal-header">
          <h4 className="modal-title">{titulo}</h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <table className="table text-center">
          <thead className="thead-dark text-center">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Fecha</th>
              <th scope="col">Usuario que Tramitó</th>
              <th scope="col">Tipo de Modificación</th>
              <th scope="col">Comentarios</th>
            </tr>
          </thead>
          <tbody>
            {listaResoluciones.map((item) => (
              <tr key={item.id}>
                <th>{item.id}</th>
                <td>{celdaFechaSolicitud(item.fechaCambio)}</td>
                <td>{item.rolAutorizador}</td>
                <td style={{width:"20%"}}>{celdaEstadoSolicitud(item.tipoAutorizacion)}</td>
                <td>
                  {item.comentarios === ""
                    ? "Sin Comentarios"
                    : item.comentarios}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default WatchHistoryRequest;
