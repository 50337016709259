import React, { useEffect, useState } from "react";
import sealColombia from "../images/sealColombia.png";
import escudoUnal_black from "../images/escudoUnal_black.png";
import sealColombia_black from "../images/sealColombia_black.png";
import escudoUnal from "../images/escudoUnal.svg";
import ServCall from "../images/icnServCall.png";
import icnServEmail from "../images/icnServEmail.png";
import icnServSia from "../images/icnServSia.png";
import icnServLibrary from "../images/icnServLibrary.png";
import icnServIdentidad from "../images/icnServIdentidad.png";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth/store";

const Header = () => {
  const history = useNavigate();
  const auth = useAuth();

  const logout = (e) => {
    e.preventDefault();
    auth.signout((status) => {
      if (status === 200) {
        localStorage.clear();
        history("/");
      }
    });
  };
  return (
    <div className="ui fixed menu">
      <div id="services">
        <div className="indicator d-none d-md-block"></div>
        <ul className="dropdown-menu" id="servicios">
          <li>
            <a href="http://correo.unal.edu.co" target="_blank">
              <img
                src={icnServEmail}
                width="32"
                height="32"
                alt="Correo Electrónico"
              />
              Correo Electrónico
            </a>
          </li>
          <li>
            <a href="https://dninfoa.unal.edu.co" target="_blank">
              <img
                src={icnServSia}
                width="32"
                height="32"
                alt="Dirección Nacional de Información Académica"
              />
              DNINFOA - SIA
            </a>
          </li>
          <li>
            <a href="http://bibliotecas.unal.edu.co" target="_blank">
              <img
                src={icnServLibrary}
                width="32"
                height="32"
                alt="Biblioteca"
              />
              Bibliotecas
            </a>
          </li>
          <li>
            <a href="http://personal.unal.edu.co" target="_blank">
              <img src={ServCall} width="32" height="32" alt="Convocatorias" />
              Convocatorias
            </a>
          </li>
          <li>
            <a href="http://identidad.unal.edu.co">
              <img
                src={icnServIdentidad}
                width="32"
                height="32"
                alt="Identidad UNAL"
              />
              Identidad UNAL
            </a>
          </li>
        </ul>
      </div>
      <header id="unalTop">
        <div className="logo">
          <a href="https://unal.edu.co">
            <svg width="93%" height="93%">
              <image
                xlinkHref={escudoUnal}
                width="100%"
                height="100%"
                className="hidden-print"
                alt="Escudo de la Universidad Nacional de Colombia"
              />
            </svg>
            <img
              src={escudoUnal_black}
              className="d-none d-print-block"
              alt="Escudo de la Universidad Nacional de Colombia"
            />
          </a>
        </div>
        <div className="seal">
          <img
            className="hidden-print"
            alt="Escudo de la República de Colombia"
            src={sealColombia}
            width="66"
            height="66"
          />

          <img
            className="d-none d-print-block"
            alt="Escudo de la República de Colombia"
            src={sealColombia_black}
            width="66"
            height="66"
          />
        </div>

        <div className="firstMenu d-none d-md-block">
          <div className="content-fluid">
            <nav className="navbar navbar-expand-md nav navbar-dark">
              <div
                className=" collapse navbar-collapse navbar-default"
                id="navbarSupportedContent"
              >
                {localStorage.getItem("token") === null ? (
                  <>
                    <nav id="profiles">
                      <ul className="navbar-nav mr-auto nav justify-content-end">
                        <li className="nav-item item_Aspirantes #>">
                          <a href="http://aspirantes.unal.edu.co">Aspirantes</a>
                        </li>
                        <li className="nav-item item_Estudiantes #>">
                          <a href="http://estudiantes.unal.edu.co">
                            Estudiantes
                          </a>
                        </li>
                        <li className="nav-item item_Egresados #>">
                          <a href="http://egresados.unal.edu.co">Egresados</a>
                        </li>
                        <li className="nav-item item_Docentes #>">
                          <a href="http://docentes.unal.edu.co">Docentes</a>
                        </li>
                        <li className="nav-item item_Administrativos #>">
                          <a href="http://administrativos.unal.edu.co">
                            Administrativos
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </>
                ) : (
                  <>
                    <nav id="profiles">
                      <ul className="navbar-nav mr-auto nav justify-content-end">
                        <li className="nav-item">
                          <p>
                            Sesión Iniciada como{" "}
                            {auth.user.first_name + " " + auth.user.last_name} -
                            ({auth.user.email})
                          </p>
                        </li>
                        <li className="nav-item item_Administrativos #>">
                          <a onClick={logout}>Cerrar Sesión</a>
                        </li>
                      </ul>
                    </nav>
                  </>
                )}
              </div>
              <ul className="socialLinks d-none d-md-block">
                <li>
                  <a
                    href="https://www.facebook.com/UNALOficial"
                    target="_blank"
                    className="facebook"
                    title="Página oficial en Facebook"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/UNALOficial"
                    target="_blank"
                    className="twitter"
                    title="Cuenta oficial en Twitter"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCnE6Zj2llVxcvL5I38B0Ceg"
                    target="_blank"
                    className="youtube"
                    title="Canal oficial de Youtube"
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div id="bs-navbar" className="navigation d-none d-md-block">
          <div className="site-url" id="subdominio">
            <a href="https://serviciosdfabog.unal.edu.co/">
              serviciosdfabog.unal.edu.co
            </a>
          </div>
          <div className="buscador" id="buscador">
            <div
              className="gcse-searchbox-only"
              data-resultsurl="https://unal.edu.co/resultados-de-la-busqueda/"
              data-newwindow="true"
            ></div>
          </div>
          <div className="navbar-dark mainMenu" id="main_menu_container">
            <div className="btn-group">
              <div></div>
            </div>
            <div className="btn-group">
              <div>
                <a
                  className="btn btn-default"
                  href="http://dfa.bogota.unal.edu.co/division-financiera/seccion-tesoreria/"
                  target="_blank"
                >
                  Sección de Tesorería
                </a>
              </div>
            </div>{" "}
            <div className="btn-group menu_sedes">
              <div
                className="btn btn-default dropdown-toggle"
                data-toggle="dropdown"
              >
                Sedes<span className="caret"></span>
              </div>
              <ul className="dropdown-menu" id="sedes">
                <li>
                  <a
                    className="dropdown-item"
                    href="http://amazonia.unal.edu.co"
                    target="_blank"
                  >
                    Amazonia
                  </a>
                  <span className="caret-right"></span>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="http://bogota.unal.edu.co"
                    target="_blank"
                  >
                    Bogotá
                  </a>
                  <span className="caret-right"></span>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="http://caribe.unal.edu.co"
                    target="_blank"
                  >
                    Caribe
                  </a>
                  <span className="caret-right"></span>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="http://delapaz.unal.edu.co"
                    target="_blank"
                  >
                    De La Paz
                  </a>
                  <span className="caret-right"></span>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="http://www.manizales.unal.edu.co"
                    target="_blank"
                  >
                    Manizales
                  </a>
                  <span className="caret-right"></span>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="http://medellin.unal.edu.co"
                    target="_blank"
                  >
                    Medellín
                  </a>
                  <span className="caret-right"></span>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="http://orinoquia.unal.edu.co"
                    target="_blank"
                  >
                    Orinoquia
                  </a>
                  <span className="caret-right"></span>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="http://www.palmira.unal.edu.co"
                    target="_blank"
                  >
                    Palmira
                  </a>
                  <span className="caret-right"></span>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="http://tumaco-pacifico.unal.edu.co"
                    target="_blank"
                  >
                    Tumaco
                  </a>
                  <span className="caret-right"></span>
                </li>
              </ul>
            </div>
          </div>
          <div className="btn-group d-none">
            <div
              className="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
              id="navbarSupportedContent"
              data-target="#services"
            >
              Servicios<span className="caret"></span>
            </div>
          </div>
          <div className="btn-group d-none">
            <div
              className="btn btn-default dropdown-toggle"
              data-toggle="dropdown"
              id="navbarSupportedContent"
              data-target="#profiles"
            >
              Perfiles<span className="caret"></span>
            </div>
          </div>
        </div>

        <nav className="navbar navbar-light light-blue lighten-4 main_menu">
          <a className="navbar-brand d-block d-md-none" href="#"></a>

          <button
            className="navbar-toggler collapsed d-block d-md-none"
            type="button"
            data-toggle="collapse"
            data-target="#navbar_content"
            aria-controls="navbar_content"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="btn_hamburguer"
          ></button>

          <div className="collapse navbar-collapse" id="navbar_content">
            <div className="site-url" id="container_subdominio_mobil"></div>
            <div className="buscador" id="container_buscador_mobil"></div>
            <div id="container_mainmenu_mobil"></div>
            <div className="btn-group d-block d-md-none hidden-print">
              <div
                className="btn btn-default dropdown-toggle"
                data-toggle="collapse"
                data-target="#container_sedes_mobil"
                aria-controls="container_sedes_mobil"
              >
                Sedes<span className="caret"> </span>
              </div>
            </div>
            <div className="collapse" id="container_sedes_mobil"></div>

            <div className="btn-group d-block d-md-none hidden-print">
              <div
                className="btn btn-default dropdown-toggle"
                data-toggle="collapse"
                data-target="#container_servicios_mobil"
                aria-controls="container_servicios_mobil"
              >
                Servicios<span className="caret"> </span>
              </div>
            </div>
            <div className="collapse" id="container_servicios_mobil"></div>
            {localStorage.getItem("token") === null ? (
              <>
                {" "}
                <div className="btn-group d-block d-md-none hidden-print">
                  <div
                    className="btn btn-default dropdown-toggle"
                    data-toggle="collapse"
                    data-target="#container_profiles_mobil"
                    aria-controls="container_profiles_mobil"
                  >
                    Perfiles<span className="caret"> </span>
                  </div>
                </div>
                <div className="collapse" id="container_profiles_mobil"></div>
              </>
            ) : (
              <>
                <div className="btn-group d-block d-md-none hidden-print">
                  <div
                    className="btn btn-default"
                    data-toggle="collapse"
                    data-target="#container_profiles_mobil"
                    aria-controls="container_profiles_mobil"
                    align="right"
                  >
                    Sesión Iniciada como{" "}
                    {auth.user.first_name + " " + auth.user.last_name}
                  </div>
                </div>
                <div className="btn-group d-block d-md-none hidden-print">
                  <div
                    className="btn btn-default"
                    data-toggle="collapse"
                    data-target="#container_profiles_mobil"
                    aria-controls="container_profiles_mobil"
                  >
                    <a onClick={logout}>Cerrar Sesión</a>
                  </div>
                </div>
              </>
            )}
          </div>
        </nav>
      </header>
      <div class="">
        <div align="left" id="letrasdfa2"><strong>Solicitudes Sección de Tesorería</strong> |  <a target="_blank" href="http://dfa.bogota.unal.edu.co/">Dirección Financiera y Administrativa - Sede Bogotá</a> </div>
    </div>
    </div>
  );
};

export default Header;
