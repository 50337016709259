import "./App.css";
import React, { Fragment } from "react";
import Menu from "./Menu";
import Login from "./Login";
import CreateRequest from "./CreateRequest";
import { Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Protected from "./Protected";
import ListRequest from "./ListRequest";
import ListUsers from "./ListUsers";

function App() {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Login />} exact />
        <Route
          path="/menu"
          element={
            <Protected>
              <Menu />
            </Protected>
          }
        />
        <Route
          path="/crear-solicitud"
          element={
            <Protected>
              <CreateRequest />
            </Protected>
          }
        />
        <Route
          path="/mis-solicitudes"
          element={
            <Protected>
              <ListRequest />
            </Protected>
          }
        />
        <Route
          path="/gestionar-solicitudes"
          element={
            <Protected>
              <ListRequest />
            </Protected>
          }
        />
        <Route
          path="/lista-solicitudes"
          element={
            <Protected>
              <ListRequest />
            </Protected>
          }
        />
        <Route
          path="/lista-solicitudes-revisadas"
          element={
            <Protected>
              <ListRequest />
            </Protected>
          }
        />
        <Route
          path="/consultar-solicitudes"
          element={
            <Protected>
              <ListRequest />
            </Protected>
          }
        />

        <Route
          path="/cumplidos-con-orpa"
          element={
            <Protected>
              <ListRequest />
            </Protected>
          }
        />
        <Route
          path="/solicitud"
          element={
            <Protected>
              <CreateRequest />
            </Protected>
          }
        />
        <Route
          path="/reportes"
          element={
            <Protected>
              <ListRequest />
            </Protected>
          }
        />
        <Route
          path="/menu-administrador"
          element={
            <Protected>
              <ListUsers />
            </Protected>
          }
        />
      </Routes>
    </Fragment>
  );
}

export default App;
