import React, { Fragment } from "react";

const Modal = ({ children, estado }) => {
  return (
    <Fragment>
      <div className="row justify-content-md-center"></div>
      {estado && (
        <div
          className="modal fade bd-example-modal-lg"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <div>{children}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Modal;
