import axios from "axios";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState("");
  const [stateMaintain, setStateMaintain] = useState(0);
  const navigate = useNavigate();

  const ismaintain = async (callback) => {
    try {
      const isMaintain = await axios.get(
        `${process.env.REACT_APP_URL}/mantenimiento/1`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      callback(isMaintain.data.estado_pagina)
      setStateMaintain(isMaintain.data.estado_pagina);
    } catch (error) {
      console.log(error);
      setStateMaintain();
      navigate("/");
    }
  };

  const isauth = async (callback) => {
    try {
      const isAuth = await axios.post(
        `${process.env.REACT_APP_URL}/is-auth/`,
        { token: localStorage.getItem("token") },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUser(isAuth.data.user);
      callback(isAuth.status);
    } catch (error) {
      setUser("");
      localStorage.clear();
      navigate("/");
      callback(error.response.status);
    }
  };
  const signin = async (username, callback) => {
    try {
      const user = await axios.post(`${process.env.REACT_APP_URL}/login/`, {
        username: username.user,
        password: username.password,
      });
      setUser(user.data.user);
      localStorage.setItem("token", user.data.token);
      callback(user.status);
    } catch (error) {
      if (error.response.data === undefined) {
        callback(500);
      } else {
        callback(error.response.status);
      }
    }
  };

  const signout = async (callback) => {
    try {
      const logout = await axios.post(
        `${process.env.REACT_APP_URL}/logout/`,
        {
          user: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setUser("");
      localStorage.clear();
      callback(logout.status);
    } catch (error) {
      callback(error.response.status);
    }
  };

  const value = {
    user,
    signin,
    signout,
    isauth,
    ismaintain,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return React.useContext(AuthContext);
};

export { AuthProvider, useAuth };
