import React, { Fragment, useState, useEffect } from "react";
import Header from "./Header";
import password from "../images/transporte/password.png";
import user from "../images/transporte/user.png";
import fondo from "../images/tesoreria/fondo.jpg";
import Footer from "./Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth/store";

const Login = () => {
  const auth = useAuth();
  const history = useNavigate();
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      history("/menu");
    }
  }, []);

  const [credentials, setCredentials] = useState({
    user: "",
    password: "",
  });
  const SessionLogin = async (e) => {
    e.preventDefault();
    await axios
      .post(`${process.env.REACT_APP_URL}/login/`, {
        username: credentials.user,
        password: credentials.password,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        auth.ismaintain((estado_pagina) => {
          if (estado_pagina === 2) {
            toast.error("Página en Mantenimiento", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            localStorage.clear("token");
          } else {
            history("/menu");
          }
        });
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.warn("Error en el servidor.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (error.response.status === 400) {
          toast.warn("Clave incorrecta.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (error.response.status === 401) {
          toast.error(error.response.data.error, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  return (
    <body
      style={{
        backgroundImage: `url(${fondo})`,
        backgroundSize: "cover",
        height: "100% !important",
        overflow: "hidden",
      }}
    >
      <Fragment>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Header />
        <main className="detalle">
          <div className="row">
            <div className="col-xl-7 col-lg-8 col-md-12 banner-left">
              <div style={{ marginTop: "11%" }}>
                <h6 className="text-white">
                  <strong>Sede Bogotá </strong>
                  <span style={{ color: "white" }}>
                    | Por una gestión inteligente del Campus
                  </span>
                </h6>

                <h1 className="text-white">SOLICITUDES TESORERIA SEDE BOGOTÁ</h1>
                <div
                  className="card"
                  style={{ background: "black", opacity: 0.9, marginTop: "5%" }}
                >
                  <div className="card-body" style={{ lineHeight: 1.5 }}>
                    <p className="card-text">
                      Por medio de esta plataforma podrá radicar sus solicitudes
                      de pago de resoluciones de ordenación de gasto de
                      vinculación de estudiantes, con los soportes
                      correspondientes, según las condiciones de pago
                      estipuladas.
                      <br></br>
                      Este trámite{" "}
                      <a style={{ color: "#1c9888" }}>únicamente </a>podrá
                      hacerse usando el correo institucional del beneficiario
                      (para solicitar el pago) y del responsable del seguimiento
                      (para autorizar el pago). Si requiere información
                      adicional, puede comunicarse al correo:{" "}
                      <a style={{ color: "#1c9888" }} href="mailto:contactesor_bog@unal.edu.co">
                        contactesor_bog@unal.edu.co
                      </a>
                      .
                      {/*<br></br><br></br>
                      <strong>Información Importante Cierre de Vigencia 2023:</strong><br></br>
                      En atención al Cronograma de Cierre Financiero para la Vigencia 2023 establecido en el 
                      <a style={{ color: "#1c9888" }} href="Comunicado362023.pdf" target="_blank"> Comunicado 
                      No. 36 de 2023 de la Dirección Financiera y Administrativa</a>, la Sección de Tesorería
                      informa que a partir del jueves 7 de diciembre de 2023, se suspende la recepción de nuevas solicitudes de pago.
                      <br></br>
                      Adicionalmente, se informa que el <strong>martes 12 de diciembre de 2023</strong> es la <strong>fecha límite para la aprobación de las
                      constancias de cumplimiento</strong> por parte de las personas responsables de segumiento.*/}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-lg-4 col-md-12">
              <div className="d-flex justify-content-center">
                <div className="card" style={{ marginTop: "40%" }}>
                  <div className="card-header">
                    <h5>Ingreso</h5>
                  </div>
                  <div className="card-body">
                    <form style={{ marginTop: "5%" }}>
                      <div className="form-group">
                        <label style={{ color: "#5b5b5b" }}>
                          Usuario Institucional (sin el @unal.edu.co)
                        </label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <img
                                src={user}
                                style={{ width: "15px", marginLeft: "20%" }}
                              ></img>
                            </span>
                          </div>
                          <input
                            type="text"
                            onChange={(e) =>
                              setCredentials({
                                ...credentials,
                                user: e.target.value,
                              })
                            }
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label style={{ color: "#5b5b5b" }}>Contraseña</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <img
                                src={password}
                                style={{ width: "15px", marginLeft: "20%" }}
                              ></img>
                            </span>
                          </div>
                          <input
                            type="password"
                            className="form-control"
                            onChange={(e) =>
                              setCredentials({
                                ...credentials,
                                password: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          className="btn float-right login_btn"
                          onClick={SessionLogin}
                        >
                          {" "}
                          Ingresar
                        </button>
                      </div>
                    </form>
                  </div>
                  <div
                    className="card-footer login-footer-card"
                    style={{ backgroundColor: "#5b5b5b" }}
                  >
                    <p>
                      Al registrarte e iniciar sesión, aceptas
                      <a
                        href="http://unal.edu.co/tratamiento-de-datos-personales.html"
                        target="_blank"
                      >
                        {" "}
                        la política de tratamiento de datos personales de la
                        U.N.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </Fragment>
    </body>
  );
};

export default Login;
