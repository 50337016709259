import { useNavigate, Navigate, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useAuth } from "../context/auth/store";
import { useEffect } from "react";
const Protected = ({ children }) => {
  const location = useLocation();
  const auth = useAuth();
  useEffect(() => {
    auth.isauth((status) => {
      if (status !== 200) {
        return <Navigate to="/" />;
      }
    });
  }, [location.pathname]);
  if (location.pathname === "/lista-solicitudes") {
    if (auth.user.rol === 0 || auth.user.rol === 2 || auth.user.rol === 3) {
      return <Navigate to="/" />;
    }
  }
  if (location.pathname === "/consultar-solicitudes") {
    if (
      auth.user.rol === 0 ||
      auth.user.rol === 1 ||
      auth.user.rol === 2 ||
      auth.user.rol === 5
    ) {
      return <Navigate to="/" />;
    }
  }

  if (location.pathname === "/lista-solicitudes-revisadas") {
    if (auth.user.rol === 0 || auth.user.rol === 1 || auth.user.rol === 3) {
      return <Navigate to="/" />;
    }
  }
  if (location.pathname === "/cumplidos-con-orpa") {
    if (
      auth.user.rol === 0 ||
      auth.user.rol === 1 ||
      auth.user.rol === 2 ||
      auth.user.rol === 4
    ) {
      return <Navigate to="/" />;
    }
  }
  if (location.pathname === "/menu-administrador") {
    if (auth.user.rol !== 6) {
      return <Navigate to="/" />;
    }
  }
  if (auth.user !== "") {
    return children;
  }
};
export default Protected;
