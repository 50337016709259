import logo from "../images/log_orgullo.png";
import logo_black from "../images/log_orgullo_black.png";
import log_agenc from "../images/log_agenc.png";
import log_agenc_black from "../images/log_agenc_black.png";
import log_gobierno from "../images/log_gobiern.png";
import log_contra from "../images/log_contra.png";
import React, { Fragment } from "react";
import "./App.css";
const Footer = () => {
  return (
    <Fragment>
      <footer className="clear container-fluid">
        <div className="row">
          <nav className="col-lg-3 col-md-3 col-sm-4 col-6 gobiernoLinea">
            <a href="http://www.legal.unal.edu.co" target="_top">
              Régimen Legal
            </a>
            <a href="http://personal.unal.edu.co" target="_top">
              Talento humano
            </a>
            <a href="http://contratacion.unal.edu.co" target="_top">
              Contratación
            </a>
            <a href="http://personal.unal.edu.co" target="_top">
              Ofertas de empleo
            </a>
            <a href="http://launalcuenta.unal.edu.co/" target="_top">
              Rendición de cuentas
            </a>
            <a
              href="http://docentes.unal.edu.co/concurso-profesoral/"
              target="_top"
            >
              Concurso docente
            </a>
            <a href="http://www.pagovirtual.unal.edu.co/" target="_top">
              Pago Virtual
            </a>
            <a href="http://controlinterno.unal.edu.co/" target="_top">
              Control interno
            </a>
            <a href="http://siga.unal.edu.co" target="_top">
              Calidad
            </a>
            <a
              href="http://unal.edu.co/buzon-de-notificaciones/"
              target="_self"
            >
              Buzón de notificaciones
            </a>
          </nav>
          <nav className="col-lg-3 col-md-3 col-sm-4 col-6 gobiernoLinea">
            <a href="http://correo.unal.edu.co" target="_top">
              Correo institucional
            </a>
            <a href="index.html#">Mapa del sitio</a>
            <a href="http://redessociales.unal.edu.co" target="_top">
              Redes Sociales
            </a>
            <a href="index.html#">FAQ</a>
            <a href="http://unal.edu.co/quejas-y-reclamos/" target="_self">
              Quejas y reclamos
            </a>
            <a href="http://unal.edu.co/atencion-en-linea/" target="_self">
              Atención en línea
            </a>
            <a href="http://unal.edu.co/encuesta/" target="_self">
              Encuesta
            </a>
            <a href="index.html#">Contáctenos</a>
            <a href="http://estadisticas.unal.edu.co/" target="_top">
              Estadísticas
            </a>
            <a href="index.html#">Glosario</a>
          </nav>
          <div className="col-lg-4 col-md-4 col-sm-4 col-12 footer-info">
            <div className="row footer-info-spacing">
              <p className="col-lg-6 col-md-12 col-sm-12 col-6 contacto">
                <b>Contacto:</b>
                <br /> Carrera 45 # 26-85
                <br /> Edif. Uriel Gutiérrez
                <br /> Bogotá D.C., Colombia
                <br /> (+57 1) 316 5000
              </p>
              <p className="col-lg-6 col-md-12 col-sm-12 col-6 derechos">
                <a
                  href="https://unal.edu.co/fileadmin/user_upload/docs/legal.pdf"
                  target="_blank"
                >
                  &copy; Copyright 2023
                </a>
                <br /> Algunos derechos reservados.
                <br />
                <a
                  title="Comuníquese con el administrador de este sitio web"
                  href="mailto:contactesor_bog@unal.edu.co"
                >
                  contactesor_bog@unal.edu.co
                </a>
                <br />
                <a href="index.html#">Acerca de este sitio web</a>
                <br /> Actualización: 04/01/2024
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-12 logos">
            <div className="row px-3">
              <div className="col-lg-6 col-md-12 col-sm-6 col-6 no-padding">
                <div className="row mx-1">
                  <a
                    className="col-md-12 col-sm-6 col-6"
                    href="http://orgullo.unal.edu.co"
                  >
                    <img
                      className="hidden-print"
                      alt="Orgullo UN"
                      src={logo}
                      width="78"
                      height="21"
                    />
                    <img
                      className="d-none d-print-block"
                      alt="Orgullo UN"
                      src={logo_black}
                      width="94"
                      height="37"
                    />
                  </a>
                  <a
                    className="col-md-12 col-sm-6 col-6 imgAgencia"
                    href="http://agenciadenoticias.unal.edu.co"
                  >
                    <img
                      className="hidden-print"
                      alt="Agencia de Noticias"
                      src={log_agenc}
                      width="94"
                      height="25"
                    />
                    <img
                      className="d-none d-print-block"
                      alt="Agencia de Noticias"
                      src={log_agenc_black}
                      width="94"
                      height="37"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-6 col-6 no-padding">
                <div className="row mx-1">
                  <a
                    className="col-md-12 col-sm-6 col-6"
                    href="https://www.gov.co/"
                  >
                    <img
                      alt="Portal Único del Estado Colombiano"
                      src={log_gobierno}
                      width="67"
                      height="51"
                    />
                  </a>
                  <a
                    className="col-md-12 col-sm-6 col-6"
                    href="http://www.contaduria.gov.co/"
                  >
                    <img
                      alt="Contaduría General de la República"
                      src={log_contra}
                      width="67"
                      height="51"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
